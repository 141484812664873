import React, { useState, useEffect } from "react";
import { Menu, MenuItem, Tooltip, Avatar, IconButton, Box } from "@mui/material";

import { AuthContext } from "../../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import { useContext } from "react";

import { RxExit, RxUpdate  } from "react-icons/rx";
import SubHeader from "../SubHeader/index";
import "./Header.css";

import Logo from "../../assets/img/argon-react-white.png";
import MultEmp from "../MultEmp/IndexMenu";
import MultEmpContext from "../../context/MultEmpContext";

const Header = ({ title, icon }) => {
  const navigate = useNavigate();
  const [anchorElUser, setAnchorElUser] = React.useState(null)
  const [IdEmp, setIdEmp] = useState(null);
  const [Troca, setTroca] = useState(false);
  let teste = false

  const { singOut } = useContext(AuthContext);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    singOut();
    navigate("/");
  };

  async function atroca(){
    handleCloseUserMenu()
    setTroca(true);
  }

  async function OK(){
    setIdEmp(sessionStorage.getItem("@IdEmpresa"));
    setTroca(false);

    navigate(0)
  }
  
  useEffect(() => {
    
  }, [IdEmp]);

  return (
    <>
      <>
        <MultEmpContext.Provider value={{ OK }}>
          <MultEmp open={Troca}  reload={true}/> 
        </MultEmpContext.Provider>
      </>
      <header className="header">
        <div className="containerLogo">
          <Link to="/home">
            <img
              src={Logo}
              alt="Imagem Logo"
            />
          </Link>
        </div>
        <Box sx={{ flexGrow: 0 }}>
          <Tooltip>
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 1, m: 3 }}>
              <Avatar alt="Remy Sharp" src="" />
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: "0.7rem" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
            PaperProps={{
              style: {
                maxWidth: "45%",
                minWidth: "10%",
              },
            }}
          >
            { sessionStorage.getItem("@Empresas") ? (
              <MenuItem onClick={() => atroca()} id="menuIcone">
                <RxUpdate />
                Troca Empresa
              </MenuItem>
            ) : null }
            <MenuItem onClick={handleLogout} id="menuIcone">
              <RxExit />
              Sair
            </MenuItem>
          </Menu>
        </Box>
      </header>
      <SubHeader icon={icon} title={title} />
    </>
  );
};

export default Header;
