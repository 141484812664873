import React, { useState, useEffect } from "react";
import './styles.css'

export default function Subheader({ title, icon }) {
  const [NomeEmp, setNomeEmp] = useState(null);
  const [NomeUser, setNomeUser] = useState(null);

  useEffect(() => {
    setNomeUser(sessionStorage.getItem("@Auth:user"));
    setNomeEmp(sessionStorage.getItem("@razao_social"));
  }, []);

  return (
    <div className="subheader">
      <div className="alignment">
        <div className='eu'>
          <img src={require(`../../assets/icons/sidebar/${icon}.svg`)} alt="" />
          <h1>{" "+title}</h1>
        </div>

        <div>
          <h1>{NomeEmp} / {NomeUser}</h1>
        </div>
      </div>
    </div>
  )
}
