import React, { useState, useEffect, useContext } from "react";
import { Modal, ModalBody, ModalFooter, Button } from "reactstrap";
import {
    LinearProgress, Divider
} from "@material-ui/core";
import { Box, Alert } from "@mui/material";
import {
    MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon
} from "mdb-react-ui-kit";
import PedidosContext from "../../context/PedidosContext";
import { makeStyles } from "@material-ui/core/styles";
import { RxTrash } from "react-icons/rx";

import api from "../../services/api";

import "./Modais.css";

const useStyles = makeStyles((theme) => ({
  thead: { textAlign: "left" },
  ThChave: { textAlign: "left" },
  tbodt: { textAlign: "left", fontSize: 16, fontFamily: "Roboto, Helvetica, Arial, sans-serif" },
  tbChave: { textAlign: "left", fontSize: 16, fontFamily: "Roboto, Helvetica, Arial, sans-serif" },
}));

export default function EditPedido(props) {
  const [Open, setOpen] = useState(false);

  const [Retorno, setRetorno] = useState("");
  const [Load, setLoad] = useState(false);
  const [Sev, setSev] = useState("");
  const [bloq, setbloq] = useState(false);
  const [Pedido, setPedido] = useState(0);

  const configHearders = {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("@Auth:token")}`,
      cod_vendedor: parseInt(`${sessionStorage.getItem("@cod_vendedor")}`),
      cod_empresa: parseInt(`${sessionStorage.getItem("@IdEmpresa")}`),
      "Content-Type": "application/json"
    },
  };

  const { FechaModal } = useContext(PedidosContext);

  useEffect(() => {
    if (props.abre) {
      if (props.Pedido !== "") {
        console.log(props.Pedido)
        setPedido(props.Pedido);
      }

      setOpen(true);
    }
  }, [props.abre]);

  return (
    <>
      <Modal centered size="lg" className="modalMob" isOpen={Open}>
        <ModalBody>
            <MDBContainer>          
                <MDBRow>
                    <MDBCol lg="12" style={{ marginBottom: 20 }} >
                        <b>Deletar pedido</b>
                        <Divider style={{ background: "#4f4f4f" }}/>
                    </MDBCol>
                </MDBRow>      
                <MDBRow className='mb-2'>
                    <MDBCol md="12">
                        <Box className="Title">Certeza que deseja excluir o pedido {lpad(Pedido.id)}?</Box>
                        <Divider />
                    </MDBCol>
                </MDBRow>
                {Pedido.cliente ? (
                    <MDBRow className='mb-4'>
                        <MDBCol md="6">
                            <Box className="TitleInfo">CLIENTE</Box>
                            <Box className="Titlesub">{ Pedido.cliente.razao_social ? Pedido.cliente.razao_social : null  }</Box> 
                        </MDBCol>
                        <MDBCol md="2">
                            <Box className="TitleInfo">INSC. ESTADUAL</Box>
                            <Box className="Titlesub">{ Pedido.cliente.ie ? Pedido.cliente.ie : null }</Box>
                        </MDBCol>
                        <MDBCol md="4">
                            <Box className="TitleInfo">CPF/CNPJ </Box>
                            <Box className="Titlesub">{ Pedido.cliente.cnpj_cpf ? cpfCnpj(Pedido.cliente.cnpj_cpf) : null } </Box>
                        </MDBCol>
                    </MDBRow>
                ) : null }
            </MDBContainer>
        </ModalBody>
        <ModalFooter style={{ paddingTop: 5, paddingBottom: 5 }}>
            <Button style={{ borderRadius: 0}} color='success' onClick={() => Excluir()}> <RxTrash/> Excluir</Button>
            <Button style={{ borderRadius: 0}} onClick={() => Closer()} color="danger">
                Fechar
            </Button>

            <MDBRow  className='mb-1'>
                { Load ? (
                    <MDBCol md="12"><LinearProgress /></MDBCol>
                ) : null }

                { Retorno ? (
                    <Alert severity={Sev}> {Retorno}</Alert>
                ) : null }
            </MDBRow>
        </ModalFooter>
      </Modal>
    </>
  );

  async function Excluir(){
    let c = 0;
    setRetorno("")

    if(!Pedido.id){
      c++;
    }

    if(c === 0){
      setLoad(true);
      setbloq(true);

      await api.delete(
        `/pedidos/${Pedido.id}`, configHearders
      ).then((response) => {
        console.log(response)
        setLoad(false);
        setSev("success");

        setRetorno(`Pedido excluido com sucesso.`);

        setTimeout(() => {
          Closer();
          setbloq(false);
        }, 3000);
      })
      .catch((error) => {
        console.log(error);
        setbloq(false);
        setLoad(false);
        setSev("error");
        setRetorno(error.code + " - " + error.message);
      });
    }else{
      setSev("warning");
      setRetorno("Preencha todos os campos!")
    }
  }

  function Closer() {
    setOpen(false);
    FechaModal();
  }

  function cpfCnpj(value) {
    if (value !== "" && value !== undefined) {
      const cnpjCpf = value.replace(/\D/g, "");

      if (cnpjCpf.length === 11) {
        return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
      }

      return cnpjCpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "$1.$2.$3/$4-$5");
    }
  }

  function lpad(num) {
    let size = 6;
    let s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }
}
