import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon
} from "mdb-react-ui-kit";
import { Divider, Box, Alert, LinearProgress  } from "@mui/material";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import BuscaPessoa from "../modais/BuscaPessoa";
import BuscaFormaPGTO from "../modais/BuscaFormaPGTO";
import BuscaOpFiscal from "../modais/BuscaOpFiscal";
import EObservacao from "../modais/Observacao";
import { FiEdit } from "react-icons/fi"; 
import moment from "moment";

import api from "../../services/api";
import PDFDocumentsGenerator from "../../reports/ImpPedido";

import "./TelaConfirmar.css";
import ClienteContext from "../../context/ClienteContext";
import DadosVendaContext from "../../context/DadosVendaContext";
import PreviewProdutos from "../previewProdutos";

const TelaConfirmar = ({ socket }) => {
  const navigate = useNavigate();
  const [produtos, setprodutos] = useState([]);
  const [Cliente, setCliente] = useState([]);
  const [Formas, setFormas] = useState([]);
  const [OpVenda, setOpVenda] = useState([]);
  const [TVenda, setTVenda] = useState([]);
  const [NPedido, setNPedido] = useState([]);
  const [PedImp, setPedImp] = useState([]);
  const [Observacao, setObservacao] = useState("");

  const [oBuscaPessoa, setoBuscaPessoa] = useState(false);
  const [oBuscaFpgto, setoBuscaFpgto] = useState(false);
  const [oBuscaOperacao, setoBuscaOperacao] = useState(false);
  const [oObservacao, setoObservacao] = useState(false);
  const [Retorno, setRetorno] = useState("");
  const [Load, setLoad] = useState(false);
  const [Sev, setSev] = useState("");
  const [bloq, setbloq] = useState(false);
  const [BtnImp, setBtnImp] = useState(false);

  const [Padrao, setPadrao] = useState(0);
  const [PedSockt, setPedSockt] = useState([]);

  let Hoje = new Date();
  const configHearders = {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("@Auth:token")}`,
      cod_vendedor: parseInt(`${sessionStorage.getItem("@cod_vendedor")}`),
      cod_empresa: parseInt(`${sessionStorage.getItem("@IdEmpresa")}`),
      "Content-Type": "application/json"
    },
  };

  const handleNavigateProd = () => {
    navigate("/CadPedido");
  };

  const cancelCad = () => {
    localStorage.removeItem("@listProdutos");
    navigate("/CadPedido");
  };

  useEffect(() => {
    PedidoPadrao();
    calcTotal();
  }, []);

  useEffect(() => {
    let usuario = JSON.parse(sessionStorage.getItem("@user"));
    socket.on(usuario.socket, (dados) => {
      // faz qlqr coisa q quiser aí
      setPedSockt(dados)
      dados.nome = sessionStorage.getItem("@razao_social");
      dados.usuario = JSON.parse(sessionStorage.getItem("@user"));
      setPedImp(JSON.stringify(dados))
      console.log(dados)
    })
  }, [PedSockt]);

  async function calcTotal(){
    let lprod = JSON.parse(localStorage.getItem("@listProdutos"));
    setprodutos(lprod);

    let valorTotal = 0;
    let qtdTotal = 0;
    let vlrDesc = 0;
    let desconto = 0;

    let a = 0;

    for (let i = 0; i < lprod.length; i++) {
      a = ((lprod[i].vlr_total.toString()).replace(/[.]+/g,'')).replace(/[,]+/g,'.');

      valorTotal = valorTotal + parseFloat(a);
      qtdTotal = qtdTotal + parseFloat(lprod[i].quantidade);
      vlrDesc = vlrDesc + lprod[i].vlrDesc;
      desconto = desconto + lprod[i].desconto;
    }

    let dados = {
      totalVenda: valorTotal,
      qtdTotal: qtdTotal,
      vlrDesc: vlrDesc,
      desconto: desconto
    };

    await setTVenda(JSON.parse(JSON.stringify(dados)))
  }
 
  return (
    <>
      <ClienteContext.Provider value={{ FechaModal }} >
        <BuscaPessoa abre={oBuscaPessoa} pegaRegistro={selecionar} socket={socket}/>
      </ClienteContext.Provider>

      <DadosVendaContext.Provider value={{ FechaModal }} >
        <BuscaFormaPGTO abre={oBuscaFpgto} pegaRegistro={selPGTO}/>
        <BuscaOpFiscal abre={oBuscaOperacao} pegaRegistro={selCFOP}/>
        <EObservacao abre={oObservacao} pegaRegistro={selOBS} />
      </DadosVendaContext.Provider>

      <Header icon="home" title="CONFIRMAÇÃO DO PEDIDO" />
      <div className="containerTelaConf"> 
        <MDBContainer className="ContConfirmar">
          <MDBRow className='mb-1'>
            <MDBCol md="6">
              { Retorno !== "" ? (
                <Alert severity={Sev}>{Retorno}</Alert>
              ) : null}
            </MDBCol>
            
            { BtnImp ? (
              <MDBCol md="6" sm="12" style={{ display: "flex", justifyContent: "flex-end"}}>
                <MDBBtn color='warning' style={{ marginLeft: 5, borderRadius: 0 }} onClick={cancelCad}> <MDBIcon fas icon="arrow-left" /> Voltar</MDBBtn>
                {PedSockt.id ? (
                  <MDBBtn style={{ borderRadius: 0, marginLeft: 5 }} color='info' onClick={() => PDFDocumentsGenerator(PedImp)}> <MDBIcon fas icon="print" /> Imprimir</MDBBtn>
                ) : (
                  <Alert severity="info"> Aguarde o pedido {lpad(NPedido)} ser atualizado. </Alert>
                )}
              </MDBCol>
            ) : (
              <MDBCol md="6" sm="12" className="Btn" style={{ display: "flex", justifyContent: "flex-end"}}>
                <MDBBtn color='warning' style={{ borderRadius: 0 }} onClick={handleNavigateProd}> <MDBIcon fas icon="arrow-left" /> Voltar</MDBBtn>
                <MDBBtn style={{ borderRadius: 0, marginRight: 5, marginLeft: 5, }} color='success' disabled={bloq} onClick={() => Gravar()}> <MDBIcon fas icon="check-circle" /> Gravar</MDBBtn>
                <MDBBtn style={{ borderRadius: 0 }} color='danger' onClick={cancelCad}> <MDBIcon fas icon="trash" /></MDBBtn>
              </MDBCol>
            )}
          </MDBRow>
          <MDBRow  className='mb-1'>
            { Load ? (
              <MDBCol md="12"><LinearProgress /></MDBCol>
            ) : null }
          </MDBRow>
          
          <MDBRow className='mb-2'>
            <MDBCol md="12" sm="12" >
              <Box className="Title">INFORMAÇÕES DO CLIENTE</Box>
              <Divider />
            </MDBCol>
          </MDBRow>
          <MDBRow className='mb-4'>
            <MDBCol md="6">
              <Box className="TitleInfo">CLIENTE</Box>
              <Box className="Titlesub">{ Cliente.razao_social ? Cliente.razao_social : null  }</Box> 
            </MDBCol>
            <MDBCol md="2">
              <Box className="TitleInfo">INSC. ESTADUAL</Box>
              <Box className="Titlesub">{ Cliente.ie ? Cliente.ie : null }</Box>
            </MDBCol>
            <MDBCol md="4">
              <Box className="TitleInfo">CPF/CNPJ {!BtnImp ? <FiEdit   onClick={() => UCliente()} style={{ color: "#41895E", cursor: "pointer" }} /> : null } </Box>
              <Box className="Titlesub">{ Cliente.cnpj_cpf ? cpfCnpj(Cliente.cnpj_cpf) : null } </Box>
            </MDBCol>

            <MDBCol md="12">
              <Box className="TitleInfo">ENDEREÇO</Box>
              <Box className="Titlesub">{  Cliente.endereco ? Cliente.endereco + ", " + Cliente.bairro + ". nº " + Cliente.numero + " - "+ Cliente.cidade +"/"+ Cliente.uf : null }</Box>
            </MDBCol>
          </MDBRow>

          <MDBRow className='mb-3' style={{ marginTop: 10 }}>
            <MDBCol md="12">
              <Box className="Title">INFORMAÇÕES DA VENDA</Box>
              <Divider />
            </MDBCol>
          </MDBRow>
          <MDBRow className='mb-3'>
            <MDBCol md="5">
              <Box className="TitleInfo">OPERAÇÃO FISCAL {!BtnImp ? <FiEdit  onClick={() => UOperacao()} style={{ color: "#41895E", cursor: "pointer" }} /> : null } </Box>
              <Box className="Titlesub2">{ OpVenda.id? OpVenda.cfop + " - " + OpVenda.descricao : null} </Box> 
            </MDBCol>
            <MDBCol md="4">
              <Box className="TitleInfo">FORMA DE PAGAMENTO {!BtnImp ? <FiEdit  onClick={() => UFormas()} style={{ color: "#41895E", cursor: "pointer" }} /> : null } </Box>
              <Box className="Titlesub2">{ Formas.id ? Formas.nome : null } </Box>
            </MDBCol>
            <MDBCol md="3">
              <Box className="TitleInfo">OBS {!BtnImp ? <FiEdit  onClick={() => UObs()} style={{ color: "#41895E", cursor: "pointer" }} /> : null } </Box> 
              <Box className="Titlesub2">{ Observacao } </Box>
            </MDBCol>
          </MDBRow>

          <MDBRow className='mb-3' style={{ marginTop: 10 }}>
            <MDBCol md="12">
              <Box className="Title">PRODUTOS CADASTRADOS</Box>
              <Divider />
            </MDBCol>
          </MDBRow>

          <MDBRow className='mb-3'>
            <MDBCol md="12" className="BoxCard"> 
              <PreviewProdutos Produtos={produtos} MDel={0}/>
            </MDBCol>
          </MDBRow>

          <MDBRow className='mb-3 resumo'>
            <MDBCol md="3">
              <Box className="TitleInfoVal">TOTAL DE ITENS</Box>
              <Box className="TitlesubVal">{ produtos.length }</Box> 
              <Divider className="dTotalizador" />
            </MDBCol>
            <MDBCol md="3">
              <Box className="TitleInfoVal">QUANTIDADE TOTAL</Box>
              <Box className="TitlesubVal">{ fDecimal(TVenda.qtdTotal) }</Box>
              <Divider className="dTotalizador" />
            </MDBCol>
            <MDBCol md="3">
              <Box className="TitleInfoVal">DESCONTO TOTAL</Box>
              <Box className="TitlesubVal">R$ { fDecimal(TVenda.vlrDesc) }</Box>
              <Divider className="dTotalizador" />
            </MDBCol>
            <MDBCol md="3">
              <Box className="TitleInfoVal">TOTAL DA VENDA</Box>
              <Box className="TitlesubVal">R$ { fDecimal(TVenda.totalVenda) }</Box>
              <Divider className="dTotalizador" />
            </MDBCol>
          </MDBRow>
          
        </MDBContainer>
      </div>
      <Footer />
    </>
  );

  async function PedidoPadrao(){
    await api.get(
      `/pedidos/padroes`, configHearders
    ).then((response) => {
      response = response.data.padroes;
      setFormas(response.forma_pgto);
      setOpVenda(response.cfop);
      setCliente(response.cliente);
      setPadrao(response);
    })
    .catch((error) => {
      console.log(error)
      if(error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/Login";
      }
    });
  }

  function UCliente(){
    setoBuscaPessoa(true);
  }

  function UFormas(){
    setoBuscaFpgto(true);
  }

  function UOperacao(){
    setoBuscaOperacao(true);
  }

  function UObs(){
    setoObservacao(true);
  }

  function fDecimal(Valor) {
    return new Intl.NumberFormat("pt-BR", { currency: "BRL", minimumFractionDigits: 2 }).format(Valor);
  }

  function selecionar(Item) {
    if (Item !== null) {
      if (Item.id) {
        setCliente(Item);
      }
    } else {
      setCliente([]);
    }

    setoBuscaPessoa(false);
  }

  function selPGTO(Item) {
    if (Item !== null) {
      if (Item.id) {
        setFormas(Item);
      }
    } else {
      setFormas([]);
    }

    setoBuscaFpgto(false);
  }

  function selCFOP(Item) {
    if (Item !== null) {
      if (Item.id) {
        setOpVenda(Item);
      }
    } else {
      setOpVenda([]);
    }

    setoBuscaOperacao(false);
  }

  function selOBS(Item) {
    if (Item !== null) {
      if (Item) {
        setObservacao(Item);
      }
    } else {
      setObservacao("");
    }

    setoObservacao(false);
  }

  function FechaModal(){
    setoBuscaPessoa(false);
    setoBuscaFpgto(false);
    setoBuscaOperacao(false);
    setoObservacao(false);
  }

  async function Gravar(){
    let c = 0;
    setRetorno("")

    if(!Cliente.id){
      c++;
    }

    if(!OpVenda.id){
      c++;
    }

    if(!Formas.id){
      c++;
    }

    if(c === 0){
      setLoad(true);
      setbloq(true);

      let dados = {
        id_cliente: Cliente.id,
        id_cfop: OpVenda.id,
        produtos,
        id_forma_pgto: Formas.id,
        obs: Observacao,
        cod_vendedor: parseInt(`${sessionStorage.getItem("@cod_vendedor")}`),
        numero: Padrao.numero,
        data: moment(Hoje).format("YYYY-MM-DD"),
        desconto: TVenda.desconto
      }

      //console.log(JSON.stringify(dados))

      await api.post(
        `/pedidos`, dados, configHearders
      ).then((response) => {
        console.log(response)
        let Ped = {
          pedido: [response.data.pedido],
          user: JSON.parse(sessionStorage.getItem("@user")),
          cnpj: cpfCnpj(sessionStorage.getItem("@CnpjEmpresa")),
          nome: sessionStorage.getItem("@razao_social"),
        };

        setNPedido(response.data.pedido.numero)
        setPedImp(JSON.stringify(Ped));
        setLoad(false);
        setSev("success");
        setbloq(false);

        let erros = "";
        if(response.data.pedido.erros) {
          if(response.data.pedido.erros.length > 0) {
            erros =  " - Porém, Ocorreram os seguintes erros no cadastro: "+response.data.pedido.erros;
          }
        }

        setRetorno(`Item cadastrado com sucesso.${erros}`);
        setBtnImp(true);

        localStorage.removeItem("@listProdutos");

        /*setTimeout(() => {
          localStorage.removeItem("@listProdutos");
          //navigate("/Home");
          setbloq(false);
        }, 2500);*/
      })
      .catch((error) => {
        console.log(error);
        setbloq(false);
        setLoad(false);
        setSev("error");
        setRetorno(error.code + " - " + error.message);
        
        if(error.response.status) {
          if(error.response.status === 401) {
            localStorage.clear();
            window.location.href = "/Login";
          }
        }
      });
    }else{
      setSev("warning");
      setRetorno("Preencha todos os campos!")
    }
  }

  function cpfCnpj(value) {
    if (value !== "" && value !== undefined) {
      const cnpjCpf = value.replace(/\D/g, "");

      if (cnpjCpf.length === 11) {
        return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
      }

      return cnpjCpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "$1.$2.$3/$4-$5");
    }
  }

  function lpad(num) {
    let size = 6;
    let s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }
};

export default TelaConfirmar;
