import React, { useState, useEffect, useContext } from "react";
import { Button, Modal, ModalBody, ModalFooter, Row, Col, Container } from "reactstrap";
import {
    TextField, Divider, Typography
} from "@material-ui/core";
import DadosVendaContext from "../../context/DadosVendaContext";
import "./Modais.css";

export default function Observacao(props) {
  const [Open, setOpen] = useState(false);

  //Geral
  const [obs, setobs] = useState("");

  const { FechaModal } = useContext(DadosVendaContext);

  useEffect(() => {
    if (props.abre) {
        console.log(0)
        setOpen(props.abre)
    }
  }, [props.abre]);

  function selecionar() {
    props.pegaRegistro(obs);
    Closer();
  }

  return (
    <>
      <Modal centered size="md" isOpen={Open}>
        <ModalBody>
          <Container className="py-md">
            <Row>
              <Col lg="3">
                <Typography>Observação:</Typography>
              </Col>
              <Col lg="9" className="dNome">
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="Obs"
                  defaultValue={obs}
                  value={obs}
                  onChange={(event) => setobs(event.target.value)}
                  style={{
                    marginTop: -2,
                    marginBottom: 5,
                    boxShadow: "0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02)",
                    transition: "box-shadow .15s ease",
                    background: "white",
                  }}
                  size={"small"}
                />
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter style={{ paddingTop: 5, paddingBottom: 5 }}>
          <Button onClick={() => selecionar()} size="md" style={{ borderRadius: 0 }} color="primary">
            Gravar
          </Button>
          <Button onClick={() => Closer()} size="md" style={{ borderRadius: 0 }} color="danger">
            Fechar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );

  function Closer() {
    setOpen(false);
    FechaModal();
  }
}
