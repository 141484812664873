import React, { useState, useEffect } from "react";
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, tableCellClasses, styled, TablePagination,
  InputAdornment, Input, IconButton, FormControl, InputLabel, Select, MenuItem, Switch, FormControlLabel, Alert
} from '@mui/material';
import { Search } from '@mui/icons-material';
import {
  MDBRow, MDBCol
} from "mdb-react-ui-kit";
import PedidosContext from "../../context/PedidosContext";
import DetPedido from "../modais/DetPedido";
import EditPedido from "../modais/EditPedido";
import DelPedido from "../modais/DelPedido";
import api from "../../services/api";
import SemResultados from "../SemResultados/SemResultados";
import { RxZoomIn, RxSymbol, RxTrash, RxDownload, RxPencil1   } from "react-icons/rx";
import PDFDocumentsGenerator from "../../reports/ImpPedido";
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import {
  HamburgerMenuIcon
} from '@radix-ui/react-icons';
import './style.css';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#D9D9D9",
    color: "#424242",
    fontWeight: "bold",
    borderRadius: 0
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    borderRadius: 0
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
    borderRadius: "0px"
  },
}));

export default function TablePedidos({ socket }) {
  const [pageApi, setPageApi] = useState(1);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [Pedidos, setPedidos] = useState([]);

  const [Busca, setBusca] = useState("");
  const [Pedido, setPedido] = useState([]);
  const [oPedido, setoPedido] = useState(false);
  const [ePedido, setePedido] = useState(false);
  const [dPedido, setdPedido] = useState(false);
  const [TipoBusca, setTipoBusca] = useState("");
  const [fsincronizado, setfsincronizado] = useState(1);
  
  const [Aviso, setAviso] = useState("");
  const [Sev, setSev] = useState(null)
 
  const configHearders =  {
    Authorization: `Bearer ${sessionStorage.getItem("@Auth:token")}`,
    cod_vendedor: parseInt(`${sessionStorage.getItem("@cod_vendedor")}`),
    cod_empresa: parseInt(`${sessionStorage.getItem("@IdEmpresa")}`),
    "Content-Type": "application/json"
  };

  async function getPedidos() {
    try {
      let fCliente = ""
      let fSinc = "";
      if(TipoBusca === "Cliente" && Busca !== "") {
        fCliente = `&cliente=${Busca}`
      }

      if(TipoBusca === "Sincronizado") {
        fSinc = `&sincronizado=${fsincronizado}`
      }

      if(pageApi === -1) {
        setPageApi(1)
      }
      

      const { data } = await api.get(
        `pedidos?pagination=${pageApi}&limit=${rowsPerPage+fCliente+fSinc}`, {
          headers: configHearders
        }
      ).catch((error) => {
        console.log(error)
      });

      
      setPedidos(data.pedidos);
    } catch (erro) {
      console.log(erro);
    }
  }

  const handleChange = (event) => {
    setTipoBusca(event.target.value);
    getPedidos();
  };

  const changeSinc = () => {
    setfsincronizado(fsincronizado === 0 ? 1 : 0);
    setPageApi(1);
    setPage(0);
    getPedidos();
  }

  const Buscar = (event) => {
    setBusca(event.target.value);
    setPageApi(1);
    setPage(0);

    getPedidos();
  }

  const handleChangePage = (event, newPage) => {
    if(newPage < page) {
      setPageApi(newPage-1)
    }else{
      setPageApi(newPage+1)
    }
    setPage(newPage);
    getPedidos();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageApi(1);
    setPage(0);

    getPedidos();
  };

  useEffect(() => {
    getPedidos();
  }, [page, rowsPerPage, TipoBusca, Busca, fsincronizado]);

  return (
    <>
      <PedidosContext.Provider value={{ FechaModal }}>
        <DetPedido Pedido={Pedido} abre={oPedido}/>
        <EditPedido Pedido={Pedido} abre={ePedido} socket={socket} />
        <DelPedido Pedido={Pedido} abre={dPedido}/>
      </PedidosContext.Provider>
      
        <>
          <MDBRow className='mb-1 d-flex justify-content-end' style={{ paddingTop: 5 }}>
            <MDBCol md="4" className="rowTable">
              <FormControl fullWidth variant="standard"> 
                <InputLabel id="demo-simple-select-label">Tipo de busca</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={TipoBusca}
                  label="Filtro"
                  onChange={handleChange}
                >
                  <MenuItem selected={true} value={""}>Todos</MenuItem>
                  <MenuItem value={"Cliente"}>Cliente</MenuItem>
                  <MenuItem value={"Sincronizado"}>Sincronizado</MenuItem>
                </Select>
              </FormControl>
            </MDBCol>
            
            {TipoBusca !== "" ? (
              <>
                {TipoBusca === "Cliente" ? (
                  <MDBCol md="3" className="rowTable">
                    <FormControl variant="standard"> 
                      <InputLabel id="demo-simple-select-label">Pesquisa</InputLabel>                
                      <Input
                        id="pesquisa"
                        type={Busca}
                        onChange={Buscar}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                            >
                              <Search />
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </MDBCol>
                ) : TipoBusca === "Sincronizado" ? (
                  <MDBCol md="2" className='mb-1 d-flex justify-content-end rowTable'>
                    <FormControl variant="standard">             
                      <>{fsincronizado === 0 ? "Não " : null }Sincronizado</>        
                      <FormControlLabel onChange={changeSinc} control={<Switch value={fsincronizado} checked={fsincronizado === 1} />} />
                    </FormControl>
                  </MDBCol>
                ) : null }
              </>
            ) : null }
            
          </MDBRow>

          { Aviso ? (
            <MDBRow className='mb-1 d-flex justify-content-end' style={{ paddingTop: 5 }}>
              <MDBCol md="12">
                <Alert severity={Sev}>
                  { Aviso }
                </Alert>
              </MDBCol>
            </MDBRow>
          ) : null }

          {Pedidos.total > 0 ? (
            <>
              <TableContainer component={Paper} style={{ padding: 5 }} >
                <Table aria-label="a dense table" responsive style={{ borderRadius: 0 }}> 
                  <TableHead style={{ borderRadius: 0 }}>
                      <TableRow>
                        <StyledTableCell align="left">Data</StyledTableCell>
                        <StyledTableCell align="left">Cliente</StyledTableCell>
                        <StyledTableCell align="right">Total Itens	</StyledTableCell>
                        <StyledTableCell align="right">Vlr. total</StyledTableCell>
                        <StyledTableCell align="left">Sincronizado</StyledTableCell>
                        <StyledTableCell align="left"></StyledTableCell>
                      </TableRow>
                  </TableHead>
                  <TableBody style={{ borderRadius: 0 }}>
                      {Pedidos.data.map((row) => (
                      <StyledTableRow key={row.id}>
                          <StyledTableCell align="left">
                            { row.sincronizado === 0 ? (
                              new Date(new Date(row.data).setHours(24)).toLocaleDateString( "pt-BR" )
                            ) : (row.data)}
                          </StyledTableCell>
                          <StyledTableCell align="left">{row.cliente.razao_social}</StyledTableCell>
                          <StyledTableCell align="right">{fDecimal(row.total_itens)}</StyledTableCell>
                          <StyledTableCell align="right">{"R$ " + fDecimal(row.valor_total)}</StyledTableCell>
                          <StyledTableCell align="left">{row.sincronizado === 0 ? "Não sincronizado" : "Sincronizado"}</StyledTableCell>
                          <StyledTableCell align="right">
                            <DropdownMenu.Root>
                              <DropdownMenu.Trigger asChild>
                                <button className="IconButton" aria-label="Customise options">
                                  <HamburgerMenuIcon />
                                </button>
                              </DropdownMenu.Trigger>

                              <DropdownMenu.Portal>
                                <DropdownMenu.Content className="DropdownMenuContent" sideOffset={5}>
                                  <DropdownMenu.Item className="DropdownMenuItem" onClick={() => Detalhar(row.id, 0)}>
                                    <RxZoomIn/> Detalhar
                                  </DropdownMenu.Item>
                                  <DropdownMenu.Separator className="DropdownMenuSeparator" />
                                  {row.sincronizado === 1 ? (
                                    <DropdownMenu.Item className="DropdownMenuItem" onClick={() => Detalhar(row.id)}>
                                      <RxDownload/> Imprimir
                                    </DropdownMenu.Item>
                                  ):(
                                    <>
                                      <DropdownMenu.Item className="DropdownMenuItem" onClick={() => reSincronizar(row.id)}>
                                        <RxSymbol/> Re-sincronizar
                                      </DropdownMenu.Item>
                                      <DropdownMenu.Item className="DropdownMenuItem" onClick={() => Detalhar(row.id, 1)}>
                                        <RxPencil1/> Editar
                                      </DropdownMenu.Item>
                                      <DropdownMenu.Item className="DropdownMenuItem" onClick={() => Excluir(row)}>
                                        <RxTrash/> Excluir
                                      </DropdownMenu.Item>
                                    </>
                                  )}
                                </DropdownMenu.Content>
                              </DropdownMenu.Portal>
                            </DropdownMenu.Root>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                  </TableBody>
                  </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 20]}
                component="div"
                count={Pedidos.total}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={(event) =>
                  handleChangeRowsPerPage(event)
                }
                labelDisplayedRows={
                  ({ from, to, count }) => {
                    return '' + from + '-' + to + ' de ' + count
                  }
                }
                labelRowsPerPage={"Registros por página:"}
              />
            </>
          ) : (
            <SemResultados textoSemResultados="Não encontramos nenhum registro." />
          )}
      </>
    </>
  );

  async function Detalhar(id, abrirDet) {
    try {
      const { data } = await api.get(
        `pedidos/${id}`, {
          headers: configHearders
        }
      ).catch((error) => {
        console.log(error)
      });

      if(abrirDet === 0){
        setPedido(data.pedido);
        setoPedido(true);
      }else if(abrirDet === 1){
        setPedido(data.pedido);
        setePedido(true);
      }else{
        data.pedido.usuario = JSON.parse(sessionStorage.getItem("@user"));
        data.pedido.cnpj = cpf_Cnpj(sessionStorage.getItem("@CnpjEmpresa"));
        data.pedido.nome = sessionStorage.getItem("@razao_social");
        PDFDocumentsGenerator(JSON.stringify(data.pedido))
      }
    } catch (erro) {
      console.log(erro);
    }
  }

  async function reSincronizar(id) {
    try {
      setAviso(`Re-sincronizando pedido: ${id}`);
      setSev("info");
      const { data } = await api.post(
        `pedidos/${id}/ressincronizar`, {}, {
          headers: configHearders
        }
      ).catch((error) => {
        console.log(error)
        setAviso(`Pedido: ${id} - Erro ao sincronizar.`);
        setSev("danger");
      });

      setAviso(`Pedido: ${id} esperando liberação.`);
      setSev("success");
    } catch (erro) {
      console.log(erro);
      setAviso(`Pedido: ${id} - Erro ao sincronizar.`);
      setSev("danger");
    }

    setTimeout(() => {
      setAviso("");
      setSev("");
    }, "3500");
  }

  function Excluir(pedido){
    setPedido(pedido);
    setdPedido(true);
  }

  function FechaModal(){
    setoPedido(false);
    setePedido(false);
    setdPedido(false);

    getPedidos();
  }

  function fDecimal(Valor) {
    return new Intl.NumberFormat("pt-BR", { currency: "BRL", minimumFractionDigits: 2 }).format(Valor);
  }

  function cpf_Cnpj(value) {
    if (value !== "" && value !== undefined) {
      const cnpjCpf = value.replace(/\D/g, "");

      if (cnpjCpf.length === 11) {
        return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
      }

      return cnpjCpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "$1.$2.$3/$4-$5");
    }
  }
}
