import { useState, useEffect } from "react";
import Card from "../CardHome/CardHome";
import api from "../../services/api";

import "./GridCardsHome.css";

const GridCards = () => {
  const [Dados, setDados] = useState([]);

  const configHearders = {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("@Auth:token")}`,
      cod_vendedor: parseInt(`${sessionStorage.getItem("@cod_vendedor")}`),
      cod_empresa: parseInt(`${sessionStorage.getItem("@IdEmpresa")}`),
      "Content-Type": "application/json"
    },
  };

  async function getDados() {
    try {
      const response = await api.get(
        `/pedidos/resumo/vendedor`, configHearders);

      setDados(response.data.resumo);
      //console.log(response.data.resumo)
    } catch (erro) {
      console.log(erro);
    }
  }

  useEffect(() => {
    getDados();
  }, []);

  return (
    <div className="containerCards"> 
      <div className="grid">
        <div className="primeiraLinha">
          <Card
            title="VALOR TOTAL DE VENDA"
            value={ Dados.total_venda ? "R$ " + fDecimal(Dados.total_venda) : 0 }
          ></Card>
          <Card
            title="NÚMERO DE VENDAS"
            value={ Dados.numero_vendas ? Dados.numero_vendas  : 0  }
          ></Card>
          <Card
            title="QUANTIDADE TOTAL"
            value={ Dados.quantidade_total ? fDecimal(Dados.quantidade_total)  : 0 }
          ></Card>
          <Card
            title="TOTAL DE ITENS"
            value={ Dados.total_itens ? fDecimal(Dados.total_itens)  : 0 }
          ></Card>
        </div>
      </div>
    </div>
  );

  function fDecimal(Valor) {
    return new Intl.NumberFormat("pt-BR", { currency: "BRL", minimumFractionDigits: 2 }).format(Valor);
  }
};

export default GridCards;
