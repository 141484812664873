import React, { useContext  } from 'react';
import {
    MDBTable, MDBTableBody
} from "mdb-react-ui-kit";
import "./Tables.css";

import ProdutosContext from "../../context/ProdutosContext";

const TableProduto = (props) => {
  const { selProduto } = useContext(ProdutosContext);

  return (
    <>
      { props.Produtos.length > 0 ? (
        <MDBTable striped responsive>
          <MDBTableBody className="corpoTable">
            {props.Produtos.map((Produto) => (         
              <tr onClick={(e) => selProduto(Produto)} style={{ cursor: "pointer" }}>
                <td>{ lpad(Produto.id) + " - " + Produto.descricao + "/" + Produto.unidade}</td>
                <td>R${ fDecimal(Produto.unitario) }</td>
              </tr>
            ))}
          </MDBTableBody>
        </MDBTable>
      ) : null }
    </>
  )

  function lpad(num) {
    let size = 6;
    let s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }

  function fDecimal(Valor) {
    return new Intl.NumberFormat("pt-BR", { currency: "BRL", minimumFractionDigits: 2 }).format(Valor);
  }
}

export default TableProduto;
