import React, { useState, useEffect, useContext } from "react";
import { Modal, ModalBody, ModalFooter, Button } from "reactstrap";
import {
    LinearProgress
} from "@material-ui/core";
import { Box, Alert, Divider } from "@mui/material";
import {
    MDBContainer, MDBRow, MDBCol
} from "mdb-react-ui-kit";
import PedidosContext from "../../context/PedidosContext";
import BuscaPessoa from "../modais/BuscaPessoa";
import BuscaFormaPGTO from "../modais/BuscaFormaPGTO";
import BuscaOpFiscal from "../modais/BuscaOpFiscal";
import EObservacao from "../modais/Observacao";
import { FiEdit } from "react-icons/fi"; 
import ClienteContext from "../../context/ClienteContext";
import DadosVendaContext from "../../context/DadosVendaContext";
import PreviewProdutos from "../previewProdutos/Edit";
import EditProduto from "../modais/EditProduto";
import ProdutosContext from "../../context/ProdutosContext";
import "./Modais.css";
import api from "../../services/api";

export default function EditPedido(props) {
  const [Open, setOpen] = useState(false);
  const [PedImp, setPedImp] = useState([]);

  const [Produtos, setProdutos] = useState([]);
  const [Cliente, setCliente] = useState([]);
  const [Formas, setFormas] = useState([]);
  const [OpVenda, setOpVenda] = useState([]);
  const [TVenda, setTVenda] = useState([]);
  const [Observacao, setObservacao] = useState("");

  const [oBuscaPessoa, setoBuscaPessoa] = useState(false);
  const [oBuscaFpgto, setoBuscaFpgto] = useState(false);
  const [oBuscaOperacao, setoBuscaOperacao] = useState(false);
  const [oObservacao, setoObservacao] = useState(false);
  const [oEdit, setoEdit] = useState(false);
  const [Retorno, setRetorno] = useState("");
  const [Load, setLoad] = useState(false);
  const [Sev, setSev] = useState("");
  const [bloq, setbloq] = useState(false);
  
  const [Padrao, setPadrao] = useState(0);
  const configHearders = {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("@Auth:token")}`,
      cod_vendedor: parseInt(`${sessionStorage.getItem("@cod_vendedor")}`),
      cod_empresa: parseInt(`${sessionStorage.getItem("@IdEmpresa")}`),
      "Content-Type": "application/json"
    },
  };

  const { FechaModal } = useContext(PedidosContext);

  useEffect(() => {
    if (props.abre) {
      if (props.Pedido !== "") {
        setFormas(props.Pedido.forma_pgto);
        setOpVenda(props.Pedido.cfop);
        setCliente(props.Pedido.cliente);
        setPadrao(props.Pedido);

        calcTotal(props.Pedido.item);
      }

      setOpen(true);
    }
  }, [props.abre, Produtos]);

  return (
    <>
      <ClienteContext.Provider value={{ fechaPesq }} >
        <BuscaPessoa abre={oBuscaPessoa} pegaRegistro={selecionar} socket={props.socket} />
      </ClienteContext.Provider>

      <ProdutosContext.Provider value={{ aProduto }} >
        <EditProduto abre={oEdit} novo={oEdit} />
      </ProdutosContext.Provider>

      <DadosVendaContext.Provider value={{ fechaPesq }} >
        <BuscaFormaPGTO abre={oBuscaFpgto} pegaRegistro={selPGTO}/>
        <BuscaOpFiscal abre={oBuscaOperacao} pegaRegistro={selCFOP}/>
        <EObservacao abre={oObservacao} pegaRegistro={selOBS} />
      </DadosVendaContext.Provider>

      <Modal centered size="lg" className="modalMob" isOpen={Open}>
        <ModalBody>
          <MDBContainer>          
            <MDBRow>
              <MDBCol lg="12" style={{ marginBottom: 10 }} >
                  <b>Edição pedido: {lpad(Padrao.id)}</b>
                  <Divider style={{ background: "#4f4f4f" }}/>
              </MDBCol>
            </MDBRow>      
            <MDBRow className='mb-1'>
              <MDBCol md="12">
                <Box className="Title">INFORMAÇÕES DO CLIENTE</Box>
                <Divider style={{ background: "#4f4f4f" }}/>
              </MDBCol>
            </MDBRow>
            <MDBRow className='mb-4'>
              <MDBCol md="6">
                <Box className="TitleInfo">CLIENTE</Box>
                <Box className="Titlesub">{ Cliente.razao_social ? Cliente.razao_social : null  }</Box> 
              </MDBCol>
              <MDBCol md="2">
                <Box className="TitleInfo">INSC. ESTADUAL</Box>
                <Box className="Titlesub">{ Cliente.ie ? Cliente.ie : null }</Box>
              </MDBCol>
              <MDBCol md="4">
                <Box className="TitleInfo">CPF/CNPJ <FiEdit   onClick={() => UCliente()} style={{ color: "#41895E", cursor: "pointer" }} /> </Box>
                <Box className="Titlesub">{ Cliente.cnpj_cpf ? cpfCnpj(Cliente.cnpj_cpf) : null } </Box>
              </MDBCol>

              <MDBCol md="12">
                <Box className="TitleInfo">ENDEREÇO</Box>
                <Box className="Titlesub">{  Cliente.endereco ? Cliente.endereco + ", " + Cliente.bairro + ". nº " + Cliente.numero + " - "+ Cliente.cidade +"/"+ Cliente.uf : null }</Box>
              </MDBCol>
            </MDBRow>

            <MDBRow className='mb-2' style={{ marginTop: 10 }}>
              <MDBCol md="12">
                <Box className="Title">INFORMAÇÕES DA VENDA</Box>
                <Divider style={{ background: "#4f4f4f" }}/>
              </MDBCol>
            </MDBRow>
            <MDBRow className='mb-2'>
              <MDBCol md="5">
                  <Box className="TitleInfo">OPERAÇÃO FISCAL <FiEdit  onClick={() => UOperacao()} style={{ color: "#41895E", cursor: "pointer" }} /> </Box>
                  <Box className="Titlesub2">{ OpVenda.id? OpVenda.cfop + " - " + OpVenda.descricao : null} </Box> 
              </MDBCol>
              <MDBCol md="4">
                  <Box className="TitleInfo">FORMA DE PAGAMENTO <FiEdit  onClick={() => UFormas()} style={{ color: "#41895E", cursor: "pointer" }} />  </Box>
                  <Box className="Titlesub2">{ Formas.id ? Formas.nome : null } </Box>
              </MDBCol>
              <MDBCol md="3">
                  <Box className="TitleInfo">OBS <FiEdit  onClick={() => UObs()} style={{ color: "#41895E", cursor: "pointer" }} /> </Box> 
                  <Box className="Titlesub2">{ Observacao } </Box>
              </MDBCol>
            </MDBRow>

            <MDBRow className='mb-2' style={{ marginTop: 10 }}>
              <MDBCol md="12">
              <Box className="Title">PRODUTOS CADASTRADOS</Box>
              <Divider style={{ background: "#4f4f4f" }}/></MDBCol>
            </MDBRow>

            <MDBRow className='mb-3'>
              <MDBCol md="12" className="BoxCard"> 
                <ProdutosContext.Provider value={{ delProd }} >
                  <PreviewProdutos Produtos={Produtos} MDel={1}/>
                </ProdutosContext.Provider>
              </MDBCol>
            </MDBRow>
            <MDBRow className='mb-1'>
              <MDBCol md="12" style={{ display: "flex", justifyContent: 'flex-end' }}>
                <Button style={{ borderRadius: 0}} size="sm" color='success' onClick={() => addProd()}> + Adicionar produto</Button>
              </MDBCol>
            </MDBRow> 

            <MDBRow className='mb-3' style={{ marginTop: 10 }}>
              <MDBCol md="12">
                <Box className="Title">RESUMO DO PEDIDO</Box>
                <Divider style={{ background: "#4f4f4f" }}/>
              </MDBCol>
            </MDBRow>

            <MDBRow className='mb-3'>
              <MDBCol md="3">
                <Box className="TitleInfoVal">TOTAL DE ITENS</Box>
                <Box className="TitlesubVal">{ TVenda.totalItens }</Box> 
                <Divider className="dTotalizador" />
              </MDBCol>
              <MDBCol md="3">
                <Box className="TitleInfoVal">QUANTIDADE TOTAL</Box>
                <Box className="TitlesubVal">{ fDecimal(TVenda.qtdTotal) }</Box>
                <Divider className="dTotalizador" />
              </MDBCol>
              <MDBCol md="3">
                <Box className="TitleInfoVal">DESCONTO TOTAL</Box>
                <Box className="TitlesubVal">R$ { fDecimal(TVenda.vlrDesc) }</Box>
                <Divider className="dTotalizador" />
              </MDBCol>
              <MDBCol md="3">
                <Box className="TitleInfoVal">TOTAL DA VENDA</Box>
                <Box className="TitlesubVal">R$ { fDecimal(TVenda.totalVenda) }</Box>
                <Divider className="dTotalizador" />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </ModalBody>
        <ModalFooter style={{ paddingTop: 5, paddingBottom: 5 }}>
          <Button style={{ borderRadius: 0}} color='success' onClick={() => Gravar(PedImp)}> Gravar</Button>
          <Button style={{ borderRadius: 0}} onClick={() => Closer()} color="danger">
              Fechar
          </Button>

          <MDBRow  className='mb-1'>
            { Load ? ( <MDBCol md="12"><LinearProgress /></MDBCol> ) : null }
            { Retorno ? ( <Alert severity={Sev}> {Retorno}</Alert> ) : null }
          </MDBRow>
        </ModalFooter>
      </Modal>
    </>
  );

  async function Gravar(){
    let c = 0;
    setRetorno("")

    if(!Cliente.id){
      c++;
    }

    if(!OpVenda.id){
      c++;
    }

    if(!Formas.id){
      c++;
    }

    if(c === 0){
      setLoad(true);
      setbloq(true);

      let dados  = Padrao;
        dados.id_cliente = Cliente.id;
        dados.cliente = Cliente;
        dados.id_cfop = OpVenda.id;
        dados.item = Produtos;
        dados.id_forma_pgto = Formas.id;
        dados.obs = Observacao;
        dados.cod_vendedor = parseInt(`${sessionStorage.getItem("@cod_vendedor")}`);
        dados.numero = Padrao.numero;
        dados.desconto = TVenda.desconto;

      //        data: moment(Hoje).format("YYYY-MM-DD"),
      console.log(JSON.stringify(dados))

      await api.put(
        `/pedidos/${Padrao.id}`, dados, configHearders
      ).then((response) => {
        console.log(response)
        response = response.data

        setLoad(false);
        setSev("success");
        setbloq(false);

        let erros = "";
        if(response.erros) {
          if(response.erros.length > 0) {
            erros =  " - Porém, Ocorreram os seguintes erros na alteração: "+response.erros;
          }
        }

        setRetorno(`Pedido alterado com sucesso.${erros}`);
        setTimeout(() => {
          Closer()
        }, 2500);
      })
      .catch((error) => {
        console.log(error);
        setbloq(false);
        setLoad(false);
        setSev("error");
        setRetorno(error.code + " - " + error.message);
      });
    }else{
      setSev("warning");
      setRetorno("Preencha todos os campos!")
    }
  }

  function Closer() {
    setRetorno(null)
    setOpen(false);
    FechaModal();
    fechaPesq();
  }

  function selecionar(Item) {
    if (Item !== null) {
      if (Item.id) {
        setCliente(Item);
      }
    } else {
      setCliente([]);
    }

    setoBuscaPessoa(false);
  }

  function delProd(produtos){
    calcTotal(produtos)
  }

  async function aProduto(Item) {
    setoEdit(false);
    if (Item !== null) {
      let item = JSON.parse(Item)
      if (item.id) {
        let list = []
        if(Produtos.length > 0 ){
          list = Produtos
        }

        let adc = {
          id: item.id,
          descricao: item.descricao,
          unidade: item.unidade,
          quantidade: item.quantidade,
          vlr_total: item.vlr_total,
          desconto: item.desconto,
          unitario: item.unitario,
          vlrDesc: item.vlrDesc,
          adicionado: true
        };

        list[list.length] = adc;
        setProdutos(list);
        calcTotal(list);
      }
    } else {
      setProdutos([]);
    }
  }

  function selPGTO(Item) {
    if (Item !== null) {
      if (Item.id) {
        setFormas(Item);
      }
    } else {
      setFormas([]);
    }

    setoBuscaFpgto(false);
  }

  function selCFOP(Item) {
    if (Item !== null) {
      if (Item.id) {
        setOpVenda(Item);
      }
    } else {
      setOpVenda([]);
    }

    setoBuscaOperacao(false);
  }

  function selOBS(Item) {
    if (Item !== null) {
      if (Item) {
        setObservacao(Item);
      }
    } else {
      setObservacao("");
    }

    setoObservacao(false);
  }

  function UCliente(){
    setoBuscaPessoa(true);
  }

  function UFormas(){
    setoBuscaFpgto(true);
  }

  function UOperacao(){
    setoBuscaOperacao(true);
  }

  function UObs(){
    setoObservacao(true);
  }

  function fechaPesq(){
    setoBuscaPessoa(false);
    setoBuscaFpgto(false);
    setoBuscaOperacao(false);
    setoObservacao(false);
  }

  function cpfCnpj(value) {
    if (value !== "" && value !== undefined) {
      const cnpjCpf = value.replace(/\D/g, "");

      if (cnpjCpf.length === 11) {
        return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
      }

      return cnpjCpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "$1.$2.$3/$4-$5");
    }
  }

  function lpad(num) {
    let size = 6;
    let s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }

  function fDecimal(Valor) {
    return new Intl.NumberFormat("pt-BR", { currency: "BRL", minimumFractionDigits: 2 }).format(Valor);
  }

  async function calcTotal(lprod){
    setProdutos(lprod);

    let valorTotal = 0;
    let qtdTotal = 0;
    let vlrDesc = 0;
    let desconto = 0;
    let iTotal = 0;

    let total = 0;

    for (let i = 0; i < lprod.length; i++) {
        let totalDesconto = 0;
        total = parseFloat(lprod[i].unitario) * parseFloat(lprod[i].quantidade); 

        if((lprod[i].desconto !== null) && (lprod[i].desconto !== NaN)) {
            totalDesconto = ((total * lprod[i].desconto)/100);
        }

        if(!lprod[i].removido){
          iTotal++
        }

        valorTotal = valorTotal + (total - totalDesconto);
        qtdTotal = qtdTotal + parseFloat(lprod[i].quantidade);
        vlrDesc = vlrDesc + totalDesconto;
        desconto = desconto + lprod[i].desconto;
    }

    let dados = {
      totalVenda: valorTotal,
      qtdTotal: qtdTotal,
      vlrDesc: vlrDesc,
      desconto: desconto,
      totalItens: iTotal
    };

    await setTVenda(JSON.parse(JSON.stringify(dados)))
  }

  function addProd() {
    setoEdit(true)
  } 
}
