import React, { useState, useEffect, useContext } from "react";
import { Button, Modal, ModalBody, ModalFooter, Row, Col, Container, Alert } from "reactstrap";
import {
  Paper, LinearProgress, TableContainer, Table, TableHead, TableCell, TableRow, TableFooter, TablePagination,
  Box, Typography, TextField, Divider
} from "@material-ui/core";
import ClienteContext from "../../context/ClienteContext";
import { makeStyles } from "@material-ui/core/styles";
import api from "../../services/api";
import Radio from '@mui/joy/Radio';
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import { cpf, cnpj } from "cpf-cnpj-validator";

import CadPessoa from "./CadPessoa";
import "./Modais.css";

const useStyles = makeStyles((theme) => ({
  thead: { textAlign: "left" },
  ThChave: { textAlign: "left" },
  tbodt: { textAlign: "left", fontSize: 16, fontFamily: "Roboto, Helvetica, Arial, sans-serif" },
  tbChave: { textAlign: "left", fontSize: 16, fontFamily: "Roboto, Helvetica, Arial, sans-serif" },
}));

export default function Entidade(props) {
  const classes = useStyles();
  const [Open, setOpen] = useState(false);
  const [oCadPessoa, setoCadPessoa] = useState(false);
  const [Registros, setRegistros] = useState([]);
  const [ColorRet, setColorRet] = useState("info");

  //Geral
  const [Retorno, setRetorno] = useState("");
  const [Load, setLoad] = useState(false);
  const [Nome, setNome] = useState("");
  const [CPFCNPJ, setCPFCNPJ] = useState("");
  const [cpf_cnpj, setcpf_cnpj] = useState("");
  const [tpFiltro, settpFiltro] = useState(0);
  const [Param, setParam] = useState("");
  const [Sckt, setSckt] = useState(props.socket);

  /*Paginação */
  const [ErowsPerPage, setErowsPerPage] = React.useState(5);
  let rowsPerPage = 5;
  let page = 1;
  const [Page, setPage] = React.useState(0);
  const [PageApi, setPageApi] = React.useState(1);
  const [Anterior, setAnterior] = React.useState(0);
  const [Total, setTotal] = React.useState(0);

  const configHearders = {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("@Auth:token")}`,
      cod_vendedor: parseInt(`${sessionStorage.getItem("@cod_vendedor")}`),
      cod_empresa: parseInt(`${sessionStorage.getItem("@IdEmpresa")}`),
      "Content-Type": "application/json"
    },
  };

  const handleChange = (event) => {
    settpFiltro(parseInt(event.target.value));
  };

  const limpaPesq = () => {
    setParam("");
    setCPFCNPJ("");
    setNome("")
    setRetorno("");
  };

  const handleChangePage = (event, newPage) => {
    setAnterior(newPage - 1);
    let pg = newPage;
    setPage(newPage);
    
    if (newPage === 0) {
      setPageApi(newPage);
      page = 1
    } else {
      if (PageApi < Anterior) {
        pg = page - 1;
        if(pg === 0 ) {
          pg = 1
        }

        setPageApi(newPage - 1);
        page = pg;
      } else {
        setPageApi(newPage + 1);
        page = pg + 1;
      }
    }

    Consultar();
  };

  const handleChangeRowsPerPage = (event) => {
    setErowsPerPage(event.target.value);
    rowsPerPage = event.target.value;
    setPage(0);
    setPageApi(1);

    Consultar();
  };

  const { FechaModal } = useContext(ClienteContext);

  useEffect(() => {
    if (props.abre) {
      if (props.NomeComp !== "") {
        setNome(props.NomeComp);
        Consultar(props.NomeComp);
      } else {
        Consultar();
      }

      setOpen(true);
    }
  }, [props.abre, props.IdCliente, ErowsPerPage]);

  async function MudaNome(nomefiltro) {
    setNome(nomefiltro);
    setPage(0);
    setPageApi(1);
  }

  function selecionar(Item) {
    Item.nomeconc = Item.razao_social + " - " + cpf_Cnpj(Item.cnpj_cpf);
    props.pegaRegistro(Item);
    Closer();
  }

  return (
    <>
      <ClienteContext.Provider value={{ fechaCad }} >
        <CadPessoa abre={oCadPessoa} pegaRegistro={selecionar} param={Param} CPFCNPJ={cpf_cnpj} />
      </ClienteContext.Provider>

      <Modal centered size="xl" fullscreen={"xl"} isOpen={Open}>
        <ModalBody>
          <Container className="py-xl">
            <Row>
              <Col lg="12" sm="12" style={{ marginBottom: 20 }} ><b>Busca de entidade</b><Divider /></Col>
            </Row>
            <Row>
              <Col lg="4" sm="12" >
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Typography>Filtrar por: </Typography>
                  <Radio
                    checked={tpFiltro === 0}
                    onChange={handleChange}
                    value={0}
                    label=" Nome "
                    name="radio-buttons"
                    slotProps={{ input: { 'aria-label': 'Nome' } }}
                    style={{ marginRight: 5 }}
                  />
                  <Radio
                    checked={tpFiltro === 1}
                    onChange={handleChange}
                    value={1}
                    label=" CPF/CNPJ "
                    name="radio-buttons"
                    slotProps={{ input: { 'aria-label': 'CPF/CNPJ' } }}
                  />
                </Box>
              </Col>              
              <Col lg="6" sm="12" className="dNome">
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Typography>{ tpFiltro === 0 ? "Nome: " :  "CPF/CNPJ: "}</Typography>
                  { tpFiltro === 0 ? (
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="Nome"
                      defaultValue={Nome}
                      value={Nome}
                      onChange={(event) => MudaNome(event.target.value)}
                      onKeyUp={(e) => {      
                        if(e.keyCode === 13) { Consultar(); }
                      }}
                      style={{
                        marginTop: -2,
                        marginBottom: 5,
                        boxShadow: "0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02)",
                        transition: "box-shadow .15s ease",
                        background: "white",
                      }}
                      size={"small"}
                    />
                  ) : (
                    <>
                      <CpfCnpj
                        id="CNPJ_CPF"
                        required
                        value={CPFCNPJ}
                        onChange={(event, type) => {
                          setCPFCNPJ(event.target.value);
                        }}
                        autoComplete="no"
                        style={{
                          width: "100%",
                          border: "1px solid #8f8f8f",
                          borderRadius:"5px",
                          padding: 5,
                          fontSize: "1em",
                          fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                          marginTop: -2,
                          marginBottom: 5,
                          boxShadow: "0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02)",
                          transition: "box-shadow .15s ease",
                          background: "white",
                        }}
                      />
                    </>
                  )}
                </Box>
              </Col>
              <Col lg="2" sm="12" className="btnConsultar">
                <Button
                  fullWidth
                  onClick={() => Consultar()}
                  color="primary"
                  style={{
                    marginBottom: 5,
                    marginTop: -2,
                    borderRadius: 0
                  }}
                >
                  Consultar
                </Button>
              </Col>
            </Row>
            { tpFiltro === 1 ? (
              <Row>
                <Col lg="12">
                  {CPFCNPJ !== "" ? (
                    <>
                      {CPFCNPJ.replace(/[^a-z0-9]/gi, "").length > 11 ? (
                        <>
                          {!cnpj.isValid(CPFCNPJ) ? (
                            <Alert color={"warning"}>*CNPJ Inválido</Alert>
                          ) : null}
                        </>
                      ) : (
                        <>
                          {!cpf.isValid(CPFCNPJ) && !cnpj.isValid(CPFCNPJ) ? (
                            <Alert color={"warning"}>*CPF / CNPJ Inválido</Alert>
                          ) : null}
                        </>
                      )}
                    </>
                  ) : null}
                </Col>
              </Row>
            ): null }
          
          <div className="mt-2"></div>

          {Load ? <LinearProgress color="secondary" /> : null}

          <Row>
            <Col lg="12">
                { Registros.length > 0 ? (
                  <>
                    <TableContainer component={Paper} style={{ display: "block", height: 300, maxHeight: 300 }}>
                      <Table className={classes.table} aria-labelledby="tableTitle" ia-label="enhanced table">
                        <TableHead style={{ borderBottom: "3px solid #11a1ef" }}>
                          <TableRow>
                            <TableCell className={classes.ThChave}>
                              <b>CPF/CNPJ</b>
                            </TableCell>
                            <TableCell className={classes.ThChave}>
                              <b>Nome</b>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        {Registros.map((Reg) => (
                          <TableRow hover role="checkbox" tabIndex={-1} onClick={() => selecionar(Reg)} key={Reg.cnpj_cpf} style={{ cursor:"pointer" }}>
                            <TableCell className={classes.tbodt}>
                              <Box>
                                {cpf_Cnpj(Reg.cnpj_cpf)}
                              </Box>
                              <Box>
                                <small>IE: {Reg.ie}</small>
                              </Box>
                            </TableCell>
                            <TableCell className={classes.tbodt}>
                              <Box>
                                {Reg.razao_social}
                              </Box>
                              <Box>
                                <small>{Reg.cidade + "/"+ Reg.uf}</small>
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableFooter>
                          <TableRow>
                            <TablePagination
                              justifycontent="right"
                              labelRowsPerPage="Registros por página: "
                              labelDisplayedRows={({ from, to, count }) => `${from} a ${to} de ${count}`}
                              rowsPerPageOptions={[5, 10, 25]}
                              count={Total}
                              rowsPerPage={ErowsPerPage}
                              page={Page}
                              onPageChange={handleChangePage}
                              onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                          </TableRow>
                        </TableFooter>
                      </Table>
                    </TableContainer>
                  </>
                ) : null }
              </Col>
            </Row>

            <Row style={{ marginTop: 5 }}>
              { Retorno !== "" ? (
                <Col lg="12">
                  <Alert color={ColorRet}>
                    {Retorno}
                  </Alert>
                </Col>
              ) : null}
            </Row>
          
          </Container>
        </ModalBody>
        <ModalFooter style={{ paddingTop: 5, paddingBottom: 5 }}>
          <Button onClick={() => setoCadPessoa(true)} size="lg" color="info" style={{ borderRadius: 0 }}>
            Cadastrar novo
          </Button>

          <Button onClick={() => Closer()} size="lg" color="danger" style={{ borderRadius: 0 }}>
            Fechar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );

  async function Consultar(nome) {
    setLoad(true);
    setRetorno("");
    let NomeFiltro = "";
    if(tpFiltro === 0){
      if (nome !== undefined && nome.length !== 0 ) {
        NomeFiltro = nome.trim();
      } else {
        if (Nome !== undefined) {
          if (Nome.trim() !== "") {
            NomeFiltro = Nome.trim();
          }
        }
      }
    }else{
      NomeFiltro = CPFCNPJ.replace(/[^a-z0-9]/gi, "").trim();
    }

    await api.get(
      `/clientes/filtrar?param=${NomeFiltro}&pagination=${page}&limit=${ErowsPerPage}`, configHearders
    ).then((response) => {
      setLoad(false);
      setRetorno("");
      response = response.data;
      if (response.clientes.total > 0) {
        setRegistros(response.clientes.data);
        setTotal(response.clientes.total);
      } else {
        setCPFCNPJ("");
        if(NomeFiltro){
          if(tpFiltro === 1){ 
            Busca(NomeFiltro)
          }else{
            setRegistros([]);
            setRetorno("Nenhum registro encontrado.");
            setColorRet("info");
          }
        }
      }
    })
    .catch((error) => {
      console.log(error)
      if(error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/Login";
      }

      setLoad(false);
      setRetorno("Erro ao buscar os dados.");
      setColorRet("danger");
      setRegistros([]);
    });
  }

  async function Busca(cpfcnpj) {   
    if(cpfcnpj) {
      cpfcnpj = cpfcnpj.replace(/[^a-z0-9]/gi, "").trim();
      setLoad(true);
      setColorRet("info");
      setRetorno("Aguarde enquanto buscamos os dados desse cnpj/cpf");
      //setRegistros([]);

      await api.get(
        `/clientes/integrar?param=${cpfcnpj}`, configHearders
      ).then((response) => {
        response = response.data;
        if(response.cliente){
          setcpf_cnpj(cpfcnpj)
          response.cliente.filtro = "integrar"
          setParam(response.cliente)
          setLoad(false);
          setRetorno("");

          setoCadPessoa(true);
        }else{
          buscaSckt(cpfcnpj);
        }      
      })
      .catch((error) => {
        console.log(error)
        setLoad(false);
        setRetorno("Erro ao buscar os dadoss.");
        setColorRet("danger");
      });
    }
  }

  function buscaSckt(cpfcnpj){
    setLoad(true);
    setRetorno("Aguarde enquanto buscamos os dados desse cnpj/cpf");
    setcpf_cnpj(cpfcnpj)

    let usuario = JSON.parse(sessionStorage.getItem("@user"));
    Sckt.on(usuario.socket, (dados) => {
      setParam(dados)
      console.log(dados)
    })

    setTimeout(function () {
      setLoad(false);
      setRetorno("");

      setoCadPessoa(true);
    }, 8000)
  }

  function Closer() {
    rowsPerPage = 5;
    page = 1;
    setPage(0);
    setPageApi(1);

    setRetorno("");
    setOpen(false);
    setoCadPessoa(false);
    LimparDados();
    limpaPesq();

    FechaModal()
  }

  async function fechaCad(){
    await limpaPesq();
    setoCadPessoa(false);

    Consultar();
  }

  function LimparDados() {
    page = 1;
    setRegistros([]);
    setNome("");
    setCPFCNPJ(null);
    settpFiltro(0);
    setParam("");
  }

  function cpf_Cnpj(value) {
    if (value !== "" && value !== undefined) {
      const cnpjCpf = value.replace(/\D/g, "");

      if (cnpjCpf.length === 11) {
        return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
      }

      return cnpjCpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "$1.$2.$3/$4-$5");
    }
  }
}
