import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { regular, bold } from "./font";

export default async function PDFDocumentsGenerator(
  data
) {
//    console.log(JSON.parse(data))
    let pedido = JSON.parse(data);
    let formatedDataGeral = []
    let formatedItems = []
    let itemsPedido = [];
  
    itemsPedido = pedido.item;
    let cliente = pedido.cliente;
    let usuario = pedido.usuario;


    formatedDataGeral.push({
      numero: lpad(pedido.numero),
      data: new Date(new Date(pedido.data).setHours(24)).toLocaleDateString('pt-BR'),
      valor_total: pedido.valor_total?.toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
      }),
      total_itens: pedido.total_itens?.toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
      }),
    })

    if (pedido.id === data.length - 1) {
        formatedDataGeral.push({
            numero:'',
            data:'',
            valor_total:'0,00',
            total_itens: '0,00'
        })
    }

    let totalDesconto = 0;
    let Qtd = 0;
    await itemsPedido.map((item, index) => {
      let ValorDesonto = calcVlrDesc(item.quantidade, item.desconto, item.unitario);
      let totalItem = calcVlr(item.quantidade, item.desconto, item.unitario);
      Qtd = Qtd + item.quantidade;

      formatedItems.push({
          descricao: item.descricao,
          unidade: item.unidade_medida,
          unitario: item.unitario.toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
          }),
          totalItem: totalItem.toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
          }),
          quantidade: fQtd(item.quantidade),
          desconto: ValorDesonto.toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          })
      })

      totalDesconto = totalDesconto + ValorDesonto;

      if (index === data.length - 1) {
          formatedItems.push({
              descricao: "",
              unidade: "",
              quantidade: "",
              unitario: "",
              totalItem: ""
          })
      }
    })

    formatedDataGeral = formatedDataGeral[0];
  
    var columns = [
      { title: 'Descrição', dataKey: 'descricao' },
      { title: 'QTD', dataKey: 'quantidade' },
      { title: 'U.M', dataKey: 'unidade' },
      { title: 'Unit.', dataKey: 'unitario' },
      { title: 'Desconto', dataKey: 'desconto' },
      { title: 'Total', dataKey: 'totalItem' },
    ]

  const doc = new jsPDF({
    orientation: 'p',
    unit: 'mm',
    format: 'a4',
    lineHeight: 1,
    putOnlyUsedFonts:true,
    title: "Pedido "+lpad(pedido.numero)
  })

  doc.addFileToVFS("IBMPlexMono-Regular.ttf", regular);
  doc.addFont("IBMPlexMono-Regular.ttf", "IBMPlexMono", "normal");

  doc.addFileToVFS("IBMPlexMono-Bold.ttf", bold);
  doc.addFont("IBMPlexMono-Bold.ttf", "IBMPlexMonoBold", "bold");

  doc.setFontSize(24);
  doc.setFont("IBMPlexMonoBold", "bold");
  doc.setTextColor(68, 155, 103);
  doc.text(10, 15, "PDV Online", { align: 'left' })
  doc.setTextColor(77, 77, 77);
  doc.setFontSize(21);
  doc.text(10, 23, "Controle de pedido", { align: 'left' })
  doc.setLineWidth(1)
  doc.line(10, 26, 95, 26)
  doc.setFont("IBMPlexMono", "normal");
  doc.setFontSize(21);
  doc.text(10, 33, `Pedido: nº ${formatedDataGeral.numero}`, {
    align: 'left',
  })

  doc.setFontSize(10);
  //doc.setFont("IBMPlexMono", "normal");
  doc.text(200, 14, `${pedido.nome}`, { align: 'right' })
  doc.text(200, 18, `CPF/CNPJ: ${cpf_Cnpj(pedido.cnpj)}`, { align: 'right' })
  //doc.text(200, 22, `IE: ${ "dados empresa" }`, { align: 'right' })
  //doc.text(200, 28, `${"dados empresa, nº dados empresa"}`, { align: 'right' })
  //doc.text(200, 32, `${"Bairro: dados empresa"}`, { align: 'right' })
 //doc.text(200, 36, `${"dados empresa/dados empresa"}`, { align: 'right' })

  doc.setFontSize(14);
  doc.text(10, 50, `Pedido para`, { align: 'left' })
  doc.setFont("IBMPlexMonoBold", "bold");
  doc.text(10, 56, `CPF/CNPJ: ${cpf_Cnpj(cliente.cnpj_cpf)}`, { align: 'left' })
  doc.text(10, 62, `${cliente.razao_social}`, { align: 'left' })

  doc.setFontSize(12);
  doc.setFont("IBMPlexMono", "normal");
  doc.text(10, 70, `IE: ${cliente.ie}`, { align: 'left' })
  doc.text(10, 76, `${cliente.endereco+", nº "+cliente.numero}`, { align: 'left' })
  doc.text(10, 82, `${cliente.bairro}`, { align: 'left' })
  doc.text(10, 88, `${cliente.cidade+"/"+cliente.uf}`, { align: 'left' })
  
  doc.setFontSize(14);
  doc.text(200, 56, `Valor total:`, { align: 'right' })
  doc.setFontSize(18);
  doc.setFont("IBMPlexMonoBold", "bold");
  doc.text(200, 64, `${pedido.valor_total?.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
  })}`, { align: 'right' })
  doc.setLineWidth(0.2)
  doc.line(115, 66, 201, 66);

  doc.setFontSize(14);
  doc.text(115, 72, `Data do pedido: `, { align: 'left' })
  doc.setFont("IBMPlexMono", "normal");
  doc.text(200, 72, pedido.data, { align: 'right' })

  doc.setLineWidth(0.5)
  doc.line(10, 92, 201, 92);

  doc.autoTable(columns, formatedItems, {
    startY: 95,
    startX: 5,
    tableWidth: 191,
    style:{ halign: 'right', fontStyle: "IBMPlexMono"},
    theme: "striped",
    margin: {left: 10},
    head: [
      [{
          content: 'DESCRIÇÃO',
          styles: { halign: 'left'}
        },
        {
          content: 'QTD',
          styles: { halign: 'right'}
        },
        {
          content: 'U.M',
          styles: { halign: 'right'}
        },
        {
          content: 'UNIT.',
          styles: { halign: 'right'}
        },
        {
          content: 'DESCONTO',
          styles: { halign: 'right'}
        },
        {
          content: 'TOTAL',
          styles: { halign: 'right'}
        }]
    ],
    columnStyles: { 
      cellWidth: 'wrap',
      0: { halign: 'left',  },
      1: { halign: 'right',  },
      2: { halign: 'right',  },
      3: { halign: 'right',  },
      4: { halign: 'right',  },
      5: { halign: 'right',  }
    },
    headStyles: { 
      fillColor: '#449b67',
      cellWidth: 'wrap'
    }
  })

  doc.setFontSize(12);
  doc.setFont("IBMPlexMono", "normal");

  let alt = parseInt(95);
  let vzs = parseInt(itemsPedido.length);
  let Inicial = (alt+(12*vzs));

  doc.text(12, Inicial, `${pedido.obs == "" ? "" : "*OBS.: "+pedido.obs}`, { align: 'left' })

  Inicial = Inicial + 8
  doc.setLineWidth(0.2)
  doc.line(12, Inicial, 80, Inicial);

  doc.setFont("IBMPlexMonoBold", "bold");
  doc.setTextColor(68, 155, 103);
  Inicial = Inicial + 5
  doc.text(12, Inicial, `Total de itens:`, { align: 'left' })
  doc.setFont("IBMPlexMono", "normal");
  doc.setTextColor(77, 77, 77);
  Inicial = Inicial + 5
  doc.text(80, Inicial, `${itemsPedido.length}`, { align: 'right' })
  
  Inicial = Inicial + 5
  doc.line(12, Inicial, 80, Inicial);

  doc.setFont("IBMPlexMonoBold", "bold");
  doc.setTextColor(68, 155, 103);
  Inicial = Inicial + 5
  doc.text(12, Inicial, `Quantidade total:`, { align: 'left' })
  doc.setFont("IBMPlexMono", "normal");
  doc.setTextColor(77, 77, 77);
  Inicial = Inicial + 5
  doc.text(80, Inicial, `${fQtd(Qtd)}`, { align: 'right' })

  Inicial = Inicial + 5
  doc.line(12, Inicial, 80, Inicial);

  doc.setFont("IBMPlexMonoBold", "bold");
  doc.setTextColor(68, 155, 103);
  Inicial = Inicial + 5
  doc.text(12, Inicial, `Desconto total:`, { align: 'left' })
  doc.setFont("IBMPlexMono", "normal");
  doc.setTextColor(77, 77, 77);
  Inicial = Inicial + 5
  doc.text(80, Inicial, `${totalDesconto.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
  })}`, { align: 'right' })

  
  doc.setFont("IBMPlexMonoBold", "bold");
  doc.setTextColor(68, 155, 103);
  Inicial = Inicial + 50
  doc.text(12, Inicial, `AGRADECEMOS A PREFERÊNCIA`, { align: 'left' })
  Inicial = Inicial + 5
  doc.line(12, Inicial, 85, Inicial);
  doc.line(120, Inicial, 200, Inicial); // linha assinatura
  doc.setFont("IBMPlexMono", "normal");
  doc.setTextColor(77, 77, 77);
  Inicial = Inicial + 5
  doc.text(12, Inicial, `${usuario.usuario}`, { align: 'left' })
  doc.text(160, Inicial, `${cliente.razao_social}`, { align: 'center' })//nome assinatura
  
  Inicial = Inicial + 5
  doc.text(12, Inicial, `${usuario.email}`, { align: 'left' }) 
  

  var blob = doc.output('blob', ({
    filename: "Pedido ",
    type: 'application/pdf'
  }))

  window.open(URL.createObjectURL(blob))

  function lpad(num) {
    let size = 6;
    let s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }

  function fQtd(Valor) {
    return new Intl.NumberFormat("pt-BR").format(Valor);
  }

  function cpf_Cnpj(value) {
    if (value !== "" && value !== undefined) {
      const cnpjCpf = value.replace(/\D/g, "");

      if (cnpjCpf.length === 11) {
        return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
      }

      return cnpjCpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "$1.$2.$3/$4-$5");
    }
  }

  function calcVlr(qtd, desc, vlrun) {
    let total = 0; 
    if ((qtd !== "") && (vlrun !== "")) {
      let vlrDesc = 0;
      total = vlrun * qtd; 

      if((desc !== null) && (desc !== NaN)) {
        vlrDesc = ((total * desc)/100);
      }

      return total - vlrDesc;
    }
  }

  function calcVlrDesc(qtd, desc, vlrun) {
    let total = 0; 
    if ((qtd !== "") && (vlrun !== "")) {
      let vlrDesc = 0;
      total = vlrun * qtd; 

      if((desc !== null) && (desc !== NaN)) {
        vlrDesc = ((total * desc)/100);
      }

      return vlrDesc;
    }
  }
}
