import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import GridCards from "../GridCardsHome/GridCardsHome";

import HomeTabelas from "../HomeTabelas/HomeTabelas";
import Menu from "../Menu/Menu";
import Footer from "../Footer/Footer";
import MultEmp from "../MultEmp/Index";
import MultEmpContext from "../../context/MultEmpContext";

import "./TelaHome.css";

const TelaHome = ({ socket }) => {
  const [IdEmp, setIdEmp] = useState(null);

  useEffect(() => {
    setIdEmp(sessionStorage.getItem("@IdEmpresa"));
  }, []);

  async function OK(){
    setIdEmp(sessionStorage.getItem("@IdEmpresa"));
  }
  
  return (
    <>
      <Header icon="home" title="Home" />
      <div className="containerTelaHome">
        <>
            { !IdEmp ? 
              <>
                <MultEmpContext.Provider value={{ OK }}>
                  <MultEmp open={sessionStorage.getItem("@IdEmpresa")} /> 
                </MultEmpContext.Provider>
              </>
            :
              <>
                {/* <HeaderNome />*/}
                <GridCards />
                <Menu />
                <HomeTabelas socket={socket} />
              </>
            }
        </>
      </div>
      <Footer />
    </>
  );
};

export default TelaHome;
