import TelaCadastro from "../../components/TelaCadastro/TelaCadastro";

const CadPedido = () => {
  return (
    <>
      <TelaCadastro />
    </>
  );
};

export default CadPedido;
