const URLImmport = "http://177.38.236.137:8055/datasnap/rest/TServerMethods1/ImportarCNPJ/";
const URL_AUTH = "https://api.ruralhub.com.br/auth/";
const API_KEY = "f738bf01-c646-430c-b17c-939c69f34c95";
const URL_API = "https://api.ruralhub.com.br/pedidos/";
const URL_SOCKET = "https://api.ruralhub.com.br/";

export { URLImmport, URL_AUTH, API_KEY,URL_API, URL_SOCKET };


/*
    const URLImmport = "http://177.38.236.137:8055/datasnap/rest/TServerMethods1/ImportarCNPJ/";
    const URL_AUTH = "https://api.ruralhub.com.br/auth/";
    const API_KEY = "f738bf01-c646-430c-b17c-939c69f34c95";
    const URL_API = "https://api.ruralhub.com.br/pedidos/";
    const URL_SOCKET = "https://api.ruralhub.com.br/";

    export { URLImmport, URL_AUTH, API_KEY,URL_API, URL_SOCKET };

    const URLImmport = "http://177.38.236.137:8055/datasnap/rest/TServerMethods1/ImportarCNPJ/";
    const URL_AUTH = "http://192.168.0.151:3334/usuarios/";
    const API_KEY = "5b839695-ed95-453b-9931-0b772529ba3a";
    const URL_API = "http://192.168.0.151:3333/";
    const URL_SOCKET = "https://api.ruralhub.com.br/";

    export { URLImmport, URL_AUTH, API_KEY,URL_API, URL_SOCKET };
*/
