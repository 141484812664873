import axios from "axios";
import { URL_AUTH, API_KEY } from "./URLS";

const auth = new axios.create({
  baseURL: URL_AUTH,
  headers: {
    api_key: API_KEY
  }
});

export default auth;
