import TelaHome from "../../components/TelaHome/TelaHome";

const Home = ({ socket }) => {
  return (
    <>
      <TelaHome  socket={socket} />
    </>
  );
};
export default Home;
