import { createContext, useState, useEffect } from "react";

import api from "../services/api";
import auth from "../services/auth";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [erro, setErro] = useState("");
  const [errorServer, seterrorServer] = useState("");
  const [loanding, setLoanding] = useState(false);

  const loadingStoreData = () => {
    const storageUser = sessionStorage.getItem("@Auth:nome");
    const storageToken = sessionStorage.getItem("@Auth:token");

    if (storageUser && storageToken) {
      setUser(storageUser);
    }
  };

  //const signIn = async ({ email, senha }) => {    
  async function signIn(data){    
    setLoanding(true);
    try {
      //console.log(data)
      const response = await auth.post("usuarios/login", data);

      if (response.data.erro) {
        setErro("Usuário ou senha inválido");
        setLoanding(false);
      } else {
        //console.log(response.data)
        //setUser(response.data);
        let Retorno = response.data;
        await getUser(Retorno.id, Retorno.token);
        sessionStorage.setItem("@Auth:token", Retorno.token);
        /*api.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${Retorno.token}`;
        console.log(Retorno.token)*/

        if(Retorno.empresas.length === 1) {
          /*api.defaults.headers.common[
            "cod_empresa"
          ] = `${Retorno.empresas[0].id}`;*/

          sessionStorage.setItem("@IdEmpresa", parseInt(Retorno.empresas[0].id));
          sessionStorage.setItem("@CnpjEmpresa", Retorno.empresas[0].cnpj);
          sessionStorage.setItem("@razao_social", Retorno.empresas[0].razao_social);
        }else{
          sessionStorage.setItem("@Empresas", JSON.stringify(Retorno.empresas));
        }

        setLoanding(false);
      }
    } catch (erro) {
      setErro("Ocorreu um erro. Tente novamente");
      setLoanding(false);
      seterrorServer(erro.message);
      console.log(erro);
    }
  };

  async function getUser( id, token ){    
    setLoanding(true);
    try {
      const response = await api.get("usuarios/cod/" + id, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      if (response.data.erro) {
        setLoanding(false);
        return false
      } else {
        let Retorno = response.data.usuario;

        /*api.defaults.headers.common[
          "cod_vendedor"
        ] = `${Retorno.cod_vendedor}`;*/

        setUser(response.data.usuario);
        sessionStorage.setItem("@cod_vendedor", parseInt(Retorno.cod_vendedor));

        sessionStorage.setItem(
          "@Auth:user",
          Retorno.usuario
        );

        sessionStorage.setItem(
          "@user",
          JSON.stringify(Retorno)
        );

        setLoanding(false);
        return true
      }
    } catch (erro) {
      setErro("GetUser - Ocorreu um erro. Tente novamente");
      setLoanding(false);
      seterrorServer(erro.message);
      console.log(erro);
    }
  };

  const limpaErro = () => {
    setErro("");
  };

  const singOut = () => {
    sessionStorage.clear();
    localStorage.clear();
    setUser(null);
  };

  useEffect(() => {
    loadingStoreData();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        signIn,
        singOut,
        signed: !!user,
        erro,
        limpaErro,
        loanding,
        errorServer
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
