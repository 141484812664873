import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { Button, Modal, ModalBody, ModalFooter, Row, Col, Container } from "reactstrap";
import {
  LinearProgress, TextField, Divider, Box, Typography
} from "@material-ui/core";
import MaskedInput from "react-maskedinput";
import { Autocomplete, Alert } from "@material-ui/lab";
import ClienteContext from "../../context/ClienteContext";
import axios from "axios";
import api from "../../services/api";
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import { cpf, cnpj } from "cpf-cnpj-validator";

export default function CadPessoa(props) {
  const [Open, setOpen] = useState(false);
  const [Nome, setNome] = useState("");
  const [FNome, setFNome] = useState(false);
  const [CPFCnpj, setCPFCnpj] = useState("");
  const [erCPFCnpj, seterCPFCnpj] = useState("#8f8f8f");
  const [CEP, setCEP] = useState("");
  const [FCEP, setFCEP] = useState(false);
  const [Endereco, setEndereco] = useState("");
  const [FEndereco, setFEndereco] = useState(false);
  const [Numero, setNumero] = useState("");
  const [FNumero, setFNumero] = useState(false);
  const [Bairro, setBairro] = useState("");
  const [FBairro, setFBairro] = useState(false);
  const [Cidade, setCidade] = useState([]);
  const [FCidade, setFCidade] = useState(false);
  const [Municipios, setMunicipios] = useState([]);
  const [IE, setIE] = useState("");
  const [FIE, setFIE] = useState(false);
  
  //Geral
  const [Retorno, setRetorno] = useState("");
  const [Load, setLoad] = useState(false);
  const [OpenCad, setOpenCad] = useState(false);
  const [Sev, setSev] = useState("info");

  const configHearders = {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("@Auth:token")}`,
      cod_vendedor: parseInt(`${sessionStorage.getItem("@cod_vendedor")}`),
      cod_empresa: parseInt(`${sessionStorage.getItem("@IdEmpresa")}`),
      "Content-Type": "application/json"
    },
  };

  const { fechaCad } = useContext(ClienteContext);

  useEffect(() => {
    if (props.abre) {
      GetMunicipios();
      setCPFCnpj(props.CPFCNPJ);
      if (props.param) {
        setNome(props.param.razao_social);
        setIE(props.param.ie)
        setEndereco(props.param.endereco)
        setNumero(props.param.numero)
        setBairro(props.param.bairro)

        if(!props.param.filtro) {
          setaCEP(props.param.cep);
          setCPFCnpj(props.param.cnpj_cpf);
        }else{
          GETMUNINICIPIO(props.param.cidade, props.param.uf)
        }
      }
      setOpen(true);
    }
  }, [props.abre]);

  return (
    <>
      <Modal centered size="lg" isOpen={Open}>
        <ModalBody>
          <Container fluid="lg">
            <Row style={{ marginBottom: 10 }}>
              <Col lg="12" style={{ marginBottom: 20 }} ><h3>Cadastro de cliente</h3><Divider /></Col>
              <Col lg="6">
                <TextField
                  variant="standard"
                  required
                  fullWidth
                  id="Nome"
                  label="Razão social"
                  defaultValue={Nome}
                  style={{ marginTop: 3.3 }}
                  value={Nome}
                  onChange={(event) => setNome(event.target.value)}
                  size={"small"}
                  error={FNome}
                />
              </Col>

              <Col lg="6">
                <Box style={{ fontSize: 11, margin: 0, padding: 0 }}>
                  CPF / CNPJ *
                </Box>
                <CpfCnpj
                    id="CNPJ_CPF"
                    required
                    value={CPFCnpj}
                    onChange={(event, type) => {
                      setCPFCnpj(event.target.value);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    autoComplete="no"
                    style={{
                      width: "100%",
                      border: "none",
                      borderBottom: "1px solid " + erCPFCnpj,
                      padding: 2,
                      fontSize: "1em",
                      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                    }}
                  />

                {CPFCnpj !== "" ? (
                  <>
                    {CPFCnpj.replace(/[^a-z0-9]/gi, "").length > 11 ? (
                      <>
                        {!cnpj.isValid(CPFCnpj) ? (
                          <Typography variant="body2" style={{ color: "red" }}>
                            *CNPJ Inválido
                          </Typography>
                        ) : null}
                      </>
                    ) : (
                      <>
                        {!cpf.isValid(CPFCnpj) && !cnpj.isValid(CPFCnpj) ? (
                          <Typography variant="body2" style={{ color: "red" }}>
                            *CPF / CNPJ Inválido
                          </Typography>
                        ) : null}
                      </>
                    )}
                  </>
                ) : null}
              </Col>
            </Row>
            <Row style={{ marginBottom: 12 }}>
              <Col lg="3">
                <TextField
                  variant="standard"
                  required
                  fullWidth
                  id="IE"
                  label="IE"
                  defaultValue={IE}
                  style={{ marginTop: 3.3 }}
                  value={IE}
                  onChange={(event) => setIE(event.target.value)}
                  size={"small"}
                  error={FIE}
                />
              </Col>

              <Col lg="3">
                <TextField
                  variant="standard"
                  required
                  fullWidth
                  id="CEP"
                  label="CEP"
                  defaultValue={CEP}
                  style={{ marginTop: 3.3 }}
                  value={CEP}
                  onChange={(event) => setaCEP(event.target.value)}
                  InputProps={{
                    inputComponent: formatCEP,
                    form: {
                      autocomplete: "off",
                    },
                  }}
                  size={"small"}
                  error={FCEP}
                />
              </Col>

              <Col lg="6">
                <TextField
                  variant="standard"
                  required
                  fullWidth
                  id="Endereco"
                  label="Endereco"
                  defaultValue={Endereco}
                  style={{ marginTop: 3.3 }}
                  value={Endereco}
                  onChange={(event) => setEndereco(event.target.value)}
                  size={"small"}
                  error={FEndereco}
                />
              </Col>
            </Row>

            <Row style={{ marginBottom: 10 }}>
                <Col lg="2">
                  <TextField
                    variant="standard"
                    required
                    fullWidth
                    id="NUMERO"
                    label="NUMERO"
                    defaultValue={Numero}
                    style={{ marginTop: 8 }}
                    value={Numero}
                    onChange={(event) => setNumero(event.target.value)}
                    size={"small"}
                    error={FNumero}
                  />
                </Col>

                <Col lg="4">
                  <TextField
                    variant="standard"
                    required
                    fullWidth
                    id="Bairro"
                    label="Bairro"
                    defaultValue={Bairro}
                    style={{ marginTop: 8 }}
                    value={Bairro}
                    onChange={(event) => setBairro(event.target.value)}
                    size={"small"}
                    error={FBairro}
                  />
                </Col>

                <Col lg="6">
                  <Box style={{ fontSize: 12, margin: 0, padding: 0 }}>
                    Cidade / UF *
                  </Box>
                    <Autocomplete
                      id="Cidade"
                      fullWidth
                      options={Municipios}
                      style={{ marginTop: 0 }}
                      value={Cidade}
                      defaultValue={Cidade}
                      getOptionLabel={(option) => option.NomeConc}
                      getOptionSelected={(option, value) => option.NomeConc === value.NomeConc}
                      onChange={(event, newValue) => {
                        SetaCidade(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                      autoComplete="no"
                      onClick={(e) => {
                          e.preventDefault();
                      }}
                    />

                    {FCidade ? (
                      <span style={{ color: "#be2024", textAlign: "center" }}>*Campo obrigatório</span>
                    ) : null}
                </Col>
            </Row>
              
            <div className="mt-2"></div>
            {Load ? <LinearProgress color="secondary" /> : null}

            <>
              <Row style={{ MarginTop: 5 }}>
                { Retorno !== "" ? (
                  <Col lg="12"><Alert color={Sev}>{Retorno}</Alert></Col>
                ) : null}
              </Row>
            </>
          </Container>
        </ModalBody>
        <ModalFooter style={{ paddingTop: 5, paddingBottom: 5 }}>
          <Button style={{ borderRadius: 0}} onClick={() => Gravar()} size="lg" color="info">
            Cadastrar
          </Button>

          <Button style={{ borderRadius: 0}} onClick={() => Closer()} size="lg" color="danger">
            Fechar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );

  async function Gravar(){
    let c = 0;
    setRetorno("")

    setFNome(false);
    if(Nome === ""){
      c++;
      setFNome(true);
    }

    seterCPFCnpj("#8f8f8f")
    if( CPFCnpj === ""){
      c++;
      seterCPFCnpj("red")
    }

    setFIE(false);
    if(IE === ""){
      c++;
      setFIE(true);
    }

    setFCEP(false);
    if(CEP === ""){
      c++;
      setFCEP(true);
    }

    setFEndereco(false);
    if(Endereco === ""){
      c++;
      setFEndereco(true);
    }

    setFBairro(false);
    if(Bairro === ""){
      c++;
      setFBairro(true);
    }

    setFNumero(false);
    if(Numero === ""){
      c++;
      setFNumero(true);
    }

    setFCidade(false);
    if(!Cidade.nome){
      c++;
      setFCidade(true);
    }

    if(c === 0){
      setLoad(true);

      let dados = {
        razao_social: Nome,
        cnpj_cpf: CPFCnpj,
        ie: IE,
        endereco: Endereco,
        numero: Numero,
        cidade: Cidade.nome,
        uf: Cidade.microrregiao.mesorregiao.UF.sigla,
        bairro: Bairro
      }

      console.log(JSON.stringify(dados))

      await api.post(
        `/clientes/pre`, dados, configHearders
      ).then((response) => {
        console.log(response)
        setLoad(false);
        setSev("success");

        setRetorno(`Item cadastrado com sucesso.`);

        setTimeout(() => {
          props.pegaRegistro(response.data.cliente);
          Closer();
        }, 2500);
      })
      .catch((error) => {
        console.log(error);
        setLoad(false);
        setSev("error");
        setRetorno(error.response.data.error + " ("+ error.code + " - " + error.message +")");
        
        if(error.response.status) {
          if(error.response.status === 401) {
            localStorage.clear();
            window.location.href = "/Login";
          }
        }
      });
    }else{
      setSev("warning");
      setRetorno("Preencha todos os campos!")
    }
  }

  function Closer() {
    setOpen(false);
    setRetorno("");

    setNome("");
    setIE("");
    setCEP("");
    setEndereco("");
    setBairro("");
    setNumero("");
    setCidade([]);
    setCPFCnpj("");

    setFNome(false);
    setFIE(false);
    setFCEP(false);
    setFEndereco(false);
    setFBairro(false);
    setFNumero(false);
    setFCidade(false);
    seterCPFCnpj("#8f8f8f");
    setOpen(false);

    fechaCad();
  }
 
  async function GetMunicipios() {
    await axios
      .get("https://servicodados.ibge.gov.br/api/v1/localidades/municipios", {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        response = response.data;
        for (let i = 0; i < response.length; i++) {
          response[i].NomeConc = response[i].nome + " - " + response[i].microrregiao.mesorregiao.UF.sigla;
        }

        setMunicipios(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function setaCEP(cep) {
    setCEP(cep);
    let CEP = cep.replace(/[^a-z0-9]/gi, "");
    if (CEP.length === 8) {
      GetCEP(CEP);
    }
  }
  
    async function GetCEP(CEP) {
        await axios.get("https://viacep.com.br/ws/" + CEP.trim() + "/json/", {
            headers: {
            "Content-Type": "application/json",
            },
        })
        .then((response) => {
            response = response.data;
            if (response.ibge !== "") {
              GETMUN(response.ibge);
              if(response.logradouro){
                setEndereco(response.logradouro);
              }

              if(response.bairro){
                setBairro(response.bairro);
              }
              
              GETMUN(response.ibge);
              document.getElementById("NUMERO").focus();
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }

    async function GETMUN(ibge_carregamento) {
        await axios.get("https://servicodados.ibge.gov.br/api/v1/localidades/municipios", {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            response = response.data;
            for (let i = 0; i < response.length; i++) {
              if (parseInt(response[i].id) === parseInt(ibge_carregamento)) {
                response[i].NomeConc = response[i].nome + " - " + response[i].microrregiao.mesorregiao.UF.sigla;
                setCidade(response[i]);

                document.getElementById("NUMERO").focus();
                break;
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
    }

    async function GETMUNINICIPIO(CIDADE, UF) {
      await axios.get("https://servicodados.ibge.gov.br/api/v1/localidades/estados/"+UF+"/municipios", {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        response = response.data;
        for (let i = 0; i < response.length; i++) {
          if (response[i].nome.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase() == CIDADE) {
            response[i].NomeConc = response[i].nome + " - " + response[i].microrregiao.mesorregiao.UF.sigla;
            setCidade(response[i]);

            document.getElementById("CEP").focus();
            break;
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
    }

    function SetaCidade(item) {
        if (item !== null) {
          setCidade(item);
        } else {
          setCidade([]);
        }
    }
}

function formatCEP(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      mask="11.111-111"
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      placeholderChar={"\u2000"}
      showMask
    />
  );
}

formatCEP.propTypes = {
  inputRef: PropTypes.func.isRequired,
};