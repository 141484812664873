import React from "react";
import { useNavigate } from "react-router-dom";
import {
    MDBBtn, MDBContainer, MDBRow, MDBIcon
} from "mdb-react-ui-kit";
import { Box } from "@mui/material"

import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./Menu.css";

const Menu = () => {
    const navigate = useNavigate();
    const handleNavigateAtendimentos = () => {
        navigate("/CadPedido");
    };

    return (
        <div className="containerTabelaHome">
            <MDBContainer>
                <MDBRow className="d-flex justify-content-end">
                    <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                        <MDBBtn style={{ marginRight: 0, borderRadius: 0 }} onClick={handleNavigateAtendimentos}> <MDBIcon fas icon="cart-plus" /> Pedido</MDBBtn>
                    </Box>
                </MDBRow>
            </MDBContainer>
        </div>
    );
};

export default Menu;
