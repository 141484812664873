import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "../Pages/TelaLogin/TelaLogin";
import Home from "../Pages/Home/Home";
import PrivateRoute from "./privateRoutes";
import CadPedido from "../Pages/Pedidos/CadPedido";
import ConfPedido from "../Pages/Pedidos/ConfPedido";

import { URL_SOCKET } from "../services/URLS";
import  socket  from "socket.io-client";

const AppRouter = () => {
  const io = socket(URL_SOCKET);
  
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route element={<PrivateRoute />}>
          <Route path="home" element={<Home socket={io} />}  />
          <Route path="CadPedido" element={<CadPedido />}  />
          <Route path="ConfPedido" element={<ConfPedido socket={io} />} />
        </Route>
        <Route path="*" element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
