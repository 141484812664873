import React, { useState, useEffect, useContext } from "react";
import { Button, Modal, ModalBody, ModalFooter, Row, Col, Container, Alert } from "reactstrap";
import {
  Paper, LinearProgress, TableContainer, Table, TableHead, TableCell, TableRow, TableFooter, TablePagination, Typography, TextField, Divider
} from "@material-ui/core";
import DadosVendaContext from "../../context/DadosVendaContext";
import { makeStyles } from "@material-ui/core/styles";
import api from "../../services/api";
import "./Modais.css";

const useStyles = makeStyles((theme) => ({
  thead: { textAlign: "left" },
  ThChave: { textAlign: "left" },
  tbodt: { textAlign: "left", fontSize: 16, fontFamily: "Roboto, Helvetica, Arial, sans-serif" },
  tbChave: { textAlign: "left", fontSize: 16, fontFamily: "Roboto, Helvetica, Arial, sans-serif" },
}));

export default function BuscaOpFiscal(props) {
  const classes = useStyles();
  const [Open, setOpen] = useState(false);
  const [Registros, setRegistros] = useState([]);
  const [ColorRet, setColorRet] = useState("info");

  //Geral
  const [Retorno, setRetorno] = useState("");
  const [Load, setLoad] = useState(false);
  const [CFOP, setCFOP] = useState("");
  const [OpenCad, setOpenCad] = useState(false);

  /*Paginação */
  const [ErowsPerPage, setErowsPerPage] = React.useState(5);
  let rowsPerPage = 5;
  let page = 1;
  const [Page, setPage] = React.useState(0);
  const [PageApi, setPageApi] = React.useState(1);
  const [Anterior, setAnterior] = React.useState(0);
  const [Total, setTotal] = React.useState(0);

  const configHearders = {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("@Auth:token")}`,
      cod_vendedor: parseInt(`${sessionStorage.getItem("@cod_vendedor")}`),
      cod_empresa: parseInt(`${sessionStorage.getItem("@IdEmpresa")}`),
      "Content-Type": "application/json"
    },
  };

  const handleChangePage = (event, newPage) => {
    setAnterior(PageApi - 1);
    let pg = newPage;
    setPage(newPage);
    
    if (newPage === 0) {
      setPageApi(1);
      page = 1
    } else {
      if (PageApi < Anterior) {
        pg = page - 1;
        if(pg === 0 ) {
          pg = 1
        }

        setPageApi(pg);
        page = pg;
      } else {
        setPageApi(pg + 1);
        page = pg + 1;
      }
    }

    Consultar();
  };

  const handleChangeRowsPerPage = (event) => {
    setErowsPerPage(event.target.value);
    rowsPerPage = event.target.value;
    setPage(0);
    setPageApi(1);

    Consultar();
  };

  const { FechaModal } = useContext(DadosVendaContext);

  useEffect(() => {
    if (props.abre) {
      if (props.cfop !== "") {
        setCFOP(props.cfop);
        Consultar(props.cfop);
      } else {
        Consultar();
      }

      setOpen(props.abre);
    }
  }, [props.abre, props.IdCFOP ]);

  async function MudaCFOP(nomefiltro) {
    setCFOP(nomefiltro);
    setPage(0);
    setPageApi(1);
  }

  function selecionar(Item) {
    props.pegaRegistro(Item);
    Closer();
  }

  return (
    <>
      <Modal centered size="lg" isOpen={Open}>
        <ModalBody>
          <Container className="py-lg">
            <Row>
              <Col lg="12" style={{ marginBottom: 20 }} ><h3>Busca de CFOP</h3><Divider /></Col>
              <Col lg="2" sm="12">
                <Typography>CFOP:</Typography>
              </Col>
              <Col lg="6" sm="12" className="dNome">
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="CFOP"
                  defaultValue={CFOP}
                  value={CFOP}
                  onChange={(event) => MudaCFOP(event.target.value)}
                  onKeyUp={(e) => {      
                    if(e.keyCode === 13) {
                      Consultar();
                    }
                  }}
                  style={{
                    marginTop: -2,
                    marginBottom: 5,
                    boxShadow: "0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02)",
                    transition: "box-shadow .15s ease",
                    background: "white",
                  }}
                  size={"small"}
                />
              </Col>
              <Col lg="2" sm="12" className="btnConsultar">
                <Button
                  onClick={() => Consultar()}
                  color="primary"
                  style={{
                    marginBottom: 5,
                    marginTop: -2,
                    borderRadius: 0
                  }}
                >
                  {" "}
                  Consultar{" "}
                </Button>
              </Col>
            </Row>
          
          <div className="mt-2"></div>

          {Load ? <LinearProgress color="secondary" /> : null}

          <>
            { Registros.length > 0 ? (
              <>
                <TableContainer component={Paper} style={{ display: "block", height: 300, maxHeight: 300 }}>
                  <Table className={classes.table} aria-labelledby="tableTitle" ia-label="enhanced table">
                    <TableHead style={{ borderBottom: "3px solid #11a1ef" }}>
                      <TableRow>
                        <TableCell className={classes.ThChave}>
                          <b>CFOP / Descrição</b>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {Registros.map((Reg) => (
                      <TableRow hover role="checkbox" tabIndex={-1} onClick={() => selecionar(Reg)} key={Reg.cnpj_cpf} style={{ cursor:"pointer" }}>
                        <TableCell className={classes.tbodt}>{Reg.cfop + " - " + Reg.descricao}</TableCell>
                      </TableRow>
                    ))}
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          justifycontent="right"
                          labelRowsPerPage="Registros por página: "
                          labelDisplayedRows={({ from, to, count }) => `${from} a ${to} de ${count}`}
                          rowsPerPageOptions={[5, 10, 25]}
                          count={Total}
                          rowsPerPage={ErowsPerPage}
                          page={Page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </>
            ) : null }

            <Row>
              { Retorno !== "" ? (
                <Col lg="12"><Alert color={ColorRet}>{Retorno}</Alert></Col>
              ) : null}
            </Row>
          </>
          </Container>
        </ModalBody>
        <ModalFooter style={{ paddingTop: 5, paddingBottom: 5 }}>
          <Button onClick={() => Closer()} size="lg" color="danger" style={{ borderRadius: 0 }}>
            Fechar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );

  async function Consultar(nome) {
    setLoad(true);
    let NomeFiltro = "";

    if (nome !== undefined && nome.length !== 0 ) {
      NomeFiltro = nome.trim();
    } else {
      if (CFOP !== undefined) {
        if (CFOP.trim() !== "") {
          NomeFiltro = CFOP.trim();
        }
      }
    }

    await api.get(
      `/cfop/filtrar?param=${NomeFiltro}&pagination=${page}&limit=${rowsPerPage}`, configHearders
    ).then((response) => {
      setLoad(false);
      setRetorno("");
      response = response.data;
      if (response.cfop.total > 0) {
        setRegistros(response.cfop.data);
        setTotal(response.cfop.total);
      } else {
        setRegistros([]);
        setRetorno("Nenhum registro encontrado");
        setColorRet("info");
      }
    })
    .catch((error) => {
      console.log(error)
      setLoad(false);
      setRetorno("Erro ao buscar os dados.");
      setColorRet("danger");
      setRegistros([]);
    });
  }

  function Closer() {
    rowsPerPage = 5;
    page = 1;
    setPage(0);
    setPageApi(1);

    setRetorno("");
    setOpen(false);
    FechaModal();
    LimparDados();
  }

  function LimparDados() {
    page = 1;
    setRegistros([]);
    setCFOP("");
  }
}
