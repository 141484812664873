import React from "react";
import Header from "../Header/Header";
import DadosVenda from "../DadosVenda/DadosVenda";
import Footer from "../Footer/Footer";

import "./TelaCadastro.css";

const TelaCadastro = () => {
  return (
    <>
      <Header icon="home" title="NOVO PEDIDO"/>
      <div className="containerTelaCad" style={{marginTop: 50}}>
        <DadosVenda />
      </div>
      <Footer />
    </>
  );
};

export default TelaCadastro;
