import React, { useEffect, useState, useContext  } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, tableCellClasses, styled } from '@mui/material';
import { IconButton } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import EditProduto from "../modais/EditProduto";
import ProdutosContext from "../../context/ProdutosContext";

import "./index.css";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#D9D9D9",
    color: "#424242",
    fontWeight: "bold",
    borderRadius: 0
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    borderRadius: 0
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
    borderRadius: "0px"
  },
}));

export default function PreviewProdutos({ Produtos, MDel }) {
  const [LProduto, setLProduto] = useState([]);
  const [Produto, setProduto] = useState([]);
  const [oEdit, setoEdit] = useState(false);
  
  const { delProd } = useContext(ProdutosContext);

  useEffect(() => {
    if(typeof Produtos === "object"){
      setLProduto(Produtos)
    }else{
      setLProduto(JSON.parse(Produtos))
    }

  }, [Produtos, MDel, LProduto]);

  function deleteItem(index) {
    let del = LProduto;
    del[index].removido = true
    setLProduto(del);
    delProd(del);
  } 

  return (
    <>
        <ProdutosContext.Provider value={{ aProduto }} >
          <EditProduto abre={oEdit} Produto={Produto} />
        </ProdutosContext.Provider>

        <TableContainer component={Paper}>
            <Table aria-label="a dense table" responsive style={{ borderRadius: 0 }}> 
                <TableHead style={{ borderRadius: 0 }}>
                <TableRow>
                    <StyledTableCell align="left">Descrição</StyledTableCell>
                    <StyledTableCell align="right">Unitário</StyledTableCell>
                    <StyledTableCell align="right">Quantidade</StyledTableCell>
                    <StyledTableCell align="right">Desconto</StyledTableCell>
                    <StyledTableCell align="right">Total</StyledTableCell>
                    { MDel == 1 ? <StyledTableCell></StyledTableCell> : null }
                </TableRow>
                </TableHead>
                <TableBody style={{ borderRadius: 0 }}>
                {LProduto.map((row, i) => (
                  <>
                    { !row.removido ? (
                      <StyledTableRow key={row.id}>
                        <StyledTableCell align="left">{row.descricao}</StyledTableCell>
                        <StyledTableCell align="right">R$ {fDecimal(row.unitario)}</StyledTableCell>
                        <StyledTableCell align="right">{fQtd(row.quantidade)} { row.unidade ? row.unidade : row.unidade_medida } </StyledTableCell>
                        <StyledTableCell align="right">{ row.desconto }%</StyledTableCell>
                        <StyledTableCell align="right">R$ { row.vlr_total ? row.vlr_total : fDecimal(calcVlr(row.quantidade, row.desconto, row.unitario))}</StyledTableCell>
                        { MDel == 1 ? 
                            <StyledTableCell>
                            <IconButton aria-label="delete" onClick={(e) => Editar(row, i)}>
                                <Edit />
                            </IconButton>
                            <IconButton aria-label="delete" onClick={(e) => deleteItem(i)}>
                                <Delete />
                            </IconButton>
                            </StyledTableCell>
                            : null }
                      </StyledTableRow>
                    ) : (null)}
                  </>
                ))}
                </TableBody>
            </Table>
        </TableContainer>
    </>
  );

  function Editar(prod, pos) {
    prod.index = pos;
    setProduto(prod);
    setoEdit(true)
  } 

  function fDecimal(Valor) {
    return new Intl.NumberFormat("pt-BR", { currency: "BRL", minimumFractionDigits: 2 }).format(Valor);
  }

  function fQtd(Valor) {
    return new Intl.NumberFormat("pt-BR").format(Valor);
  }

  function calcVlr(qtd, desc, vlrun) {
    let total = 0; 
    if ((qtd !== "") && (vlrun !== "")) {
      let vlrDesc = 0;
      total = vlrun * qtd; 

      if((desc !== null) && (desc !== NaN)) {
        vlrDesc = ((total * desc)/100);
      }

      return total - vlrDesc;
    }
  }

  async function aProduto(Item, tipo) {
    setoEdit(false);
    if (Item !== null) {
      let item = JSON.parse(Item)
      if (item.id) {
        let list = []
        if(Produtos.length > 0 ){
          list = Produtos
        }

        let adc = {
          id: item.id,
          descricao: item.descricao,
          unidade: item.unidade,
          quantidade: item.quantidade,
          vlr_total: item.vlr_total,
          desconto: item.desconto,
          unitario: item.unitario,
          vlrDesc: item.vlrDesc,
          atualizado: true
        };

        if(tipo === 0 ){
          adc.atualizado = true
        }else{
          adc.removido = true
        }

        list[item.index] = adc;
        setLProduto(list);
      }
    } else {
      setLProduto([]);
    }
  }
}
