import React, { useEffect, useState, useContext } from "react";
import {
    Button,
    Dialog, DialogActions, DialogContent, DialogTitle,
    ListItem, List, ListItemText, LinearProgress, Box  } from "@mui/material";

    import MultEmpContext from "../../context/MultEmpContext";
    
const Index = (props) => {
    const [Open, setOpen] = useState(false);
    const [Empresas, setEmpresas] = useState(null);
    const [EmpLoad, setEmpLoad] = useState(true);

    const { OK } = useContext(MultEmpContext);

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => { 
        if(props.open) {
            setEmpresas(JSON.parse(sessionStorage.getItem("@Empresas")))
            setOpen(true);
            setEmpLoad(false);
        }
    }, [props.open]);

    return (
        <>
        <Dialog open={Open} onClose={handleClose} keepMounted={true}>
            <DialogTitle>Selecione qual empresa você gostaria de acessar:</DialogTitle>
            <DialogContent>
            { EmpLoad ? 
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
            : null }
            <List>
                { Empresas ? (
                    <>
                       {Empresas.map((Reg) => (
                            <ListItem>
                                <ListItemText
                                    primary={Reg.razao_social}
                                    secondary={cpfCnpj(Reg.cnpj)}
                                    key={Reg.id}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => SelEmp(Reg)}
                                />
                            </ListItem>
                        ))}
                    </>
                ) : null }
            </List>
            </DialogContent>
        </Dialog>
        </>
    );

    function SelEmp(obj){
        sessionStorage.setItem("@IdEmpresa", parseInt(obj.id));
        sessionStorage.setItem("@CnpjEmpresa", obj.cnpj);
        sessionStorage.setItem("@razao_social", obj.razao_social);

        setOpen(false);
        OK();
    }

    function cpfCnpj(value) {
        if (value !== "" && value !== undefined) {
          const cnpjCpf = value.replace(/\D/g, "");
    
          if (cnpjCpf.length === 11) {
            return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
          }
    
          return cnpjCpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "$1.$2.$3/$4-$5");
        }
      }
};

export default Index;
