import axios from "axios";
import { Navigate } from "react-router-dom";

import { URL_AUTH, API_KEY, URL_API } from "./URLS";

async function refreshToken() {
  let user = JSON.parse(sessionStorage.getItem("@user"));
  let dados = {
      email: user.email
  };

  console.log(dados);

  await axios.post(
      `${URL_AUTH}sessao/renovar`, dados, {
      headers: {
        api_key: API_KEY,
        Authorization: `Bearer ${sessionStorage.getItem("@Auth:token")}`
      },
  })
  .then((response) => {
    console.log(response);
    sessionStorage.setItem("@Auth:token", response.data.token);

    return response.data.token
  })
  .catch((error) => {
    console.log(error);
    sessionStorage.clear();
    localStorage.clear();
    // Se houver um erro ao renovar o token, você pode lidar com isso aqui
    Navigate("/");
  });
}

let token = sessionStorage.getItem("@Auth:token");
const api = new axios.create({
  baseURL: URL_API,
});

api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response.status === 401) {
      console.log('401')
        return refreshToken()
      .then(newToken => {
        console.log(newToken)
        // Atualiza o cabeçalho Authorization com o novo token
        sessionStorage.getItem("@Auth:token", newToken);
        token = newToken
        // Reenvia a solicitação original com os novos cabeçalhos
        return api(error.config);
      })
      .catch(refreshError => {
        console.log('catch')
        // Se houver um erro ao renovar o token, você pode lidar com isso aqui
        console.log(error);
        sessionStorage.clear();
        localStorage.clear();
        // Se houver um erro ao renovar o token, você pode lidar com isso aqui
        Navigate("/");
        
        return Promise.reject(refreshError);
      });
    }
    return Promise.reject(error);
  }
);

api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

export default api;