import React from "react";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import TablePedidos from "../previewPedidos/TablePedidos";
import "./HomeTabelas.css";

const HomeTabelas = ({ socket }) => {
  return (
    <div className="containerTabelaHome">
      <div className="containerHomeTabela">
        <TablePedidos socket={socket} />
      </div>
    </div>
  );
};

export default HomeTabelas;
