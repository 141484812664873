import React, { useState, useEffect, useContext } from "react";
import { Button, Modal, ModalBody, ModalFooter, Row, Col, Container } from "reactstrap";
import {
  MDBRow, MDBCol, MDBBtn
} from "mdb-react-ui-kit";
import ProdutosContext from "../../context/ProdutosContext";
import { makeStyles } from "@material-ui/core/styles";

import {
  TextField, Divider, InputLabel, LinearProgress, Alert, Box
} from '@mui/material';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ReactNumeric from 'react-numeric';
import TableProduto from "../Tables/TableProduto";
import api from "../../services/api";

const useStyles = makeStyles((theme) => ({
  thead: { textAlign: "left" },
  ThChave: { textAlign: "left" },
  tbodt: { textAlign: "left", fontSize: 16, fontFamily: "Roboto, Helvetica, Arial, sans-serif" },
  tbChave: { textAlign: "left", fontSize: 16, fontFamily: "Roboto, Helvetica, Arial, sans-serif" },
}));

export default function EditProduto(props) {
  const classes = useStyles();
  const [Open, setOpen] = useState(false);
  const [Produto, setProduto] = useState([]);
  const [Load, setLoad] = useState(false);
  const [Produtos, setProdutos] = useState([]);
  const [ProdutoSel, setProdutoSel] = useState([]);
  const [Index, setIndex] = useState(null);
  const [timerId, settimerId] = useState(null);
  
  const [Quantidade, setQuantidade] = useState(0);
  const [Desconto, setDesconto] = useState(0);
  const [VlrTotal, setVlrTotal] = useState(0);
  const [VlrDesconto, setVlrDesconto] = useState(null);
  const [Retorno, setRetorno] = useState("");
  const [Aviso, setAviso] = useState("");
  const [Sev, setSev] = useState("");

  const configHearders = {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("@Auth:token")}`,
      cod_vendedor: parseInt(`${sessionStorage.getItem("@cod_vendedor")}`),
      cod_empresa: parseInt(`${sessionStorage.getItem("@IdEmpresa")}`),
      "Content-Type": "application/json"
    },
  };

  const { aProduto } = useContext(ProdutosContext);

  useEffect(() => {
    if (props.abre) {
      console.log(props.Produto)
      if (props.Produto) {
        setProdutoSel(props.Produto);
        setDesconto(props.Produto.desconto)
        setQuantidade(props.Produto.quantidade)
        calcValor(props.Produto);
        setIndex(props.Produto.index)
      }
      setOpen(true);
    }
  }, [props.abre]);

  const handleNavigaConf = () => {
    let list = [];
    setAviso("");
    
    if(ProdutoSel.id){
      let c = 0;
      if(Quantidade <= 0){
        c = c + 1;
      }

      if(VlrTotal <= 0){
        c = c + 1;
      }

      if(c === 0){
        setAviso("");

        let adc = {
          id: ProdutoSel.id,
          descricao: ProdutoSel.descricao,
          unidade: ProdutoSel.unidade,
          quantidade: Quantidade,
          vlr_total: VlrTotal,
          desconto: Desconto,
          unitario: ProdutoSel.unitario,
          vlrDesc: VlrDesconto
        };
        
        if(props.Produto){
          adc.atualizado = true
          adc.index = Index
        }else{
          adc.adicionado = true
        }

        setProdutoSel([]);
        setaQtd(0);
        setaDesc(0);
        setVlrTotal(0);

        Closer(JSON.stringify(adc));
      }else{
        setAviso("Preencha os campos.")
        setSev("warning")
      }
    }else{
      setAviso("Selecione o produto.")
      setSev("warning")
    }
  };
  
  const limpaForm = () => {
    deleteItem();

  };

  const deleteItem = () => {
    setProdutoSel([]);
    setQuantidade(0);
    setVlrTotal(0);
    setDesconto(0);

    Closer(null);
  }

  return (
    <>
      <Modal centered size="lg" isOpen={Open}>
        <ModalBody>
          <Container className="py-lg">
            <MDBRow>
              <MDBCol md={12} style={{ padding: "0.8rem"}}>
                { Produto.length > 0 ? (
                  <b>Produto: {Produto.descricao}</b>
                ) : ( <b>Adicionar Produto</b> )}
                <Divider style={{ background: "#4f4f4f" }}/>
              </MDBCol>
            </MDBRow>
          
          <div className="mt-1"></div>

          <MDBRow>
            <MDBCol md="12" sm="12" className="boxCard"> 
              { !props.Produto ? (
                <MDBCol md={12} style={{ padding: "0.8rem"}}>
                  <TextField
                    value={Produto}
                    placeholder="Produto"
                    label="Produto"
                    variant="outlined"
                    disabled={!props.novo}
                    fullWidth
                    style={{
                      marginTop: -2,
                      background: "white",
                      border: 0,
                      boxShadow: "0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02)",
                      transition: "box-shadow .15s ease",
                      paddingRight: 0,
                    }}
                    size="small"
                    id="Produto"
                    autoComplete="off"
                    onFocus={(e) => {
                      e.preventDefault()
                    }}
                    onChange={(event) =>
                      setProduto(event.target.value)
                    }

                    onKeyUp={(e) => {
                      clearTimeout(timerId);

                      if(Produto.length > 0) {
                      settimerId(
                        setTimeout(function () { getProdutos(); }, 300)
                      );
                      }
                    }}

                    onKeyDownCapture={(e) => {
                      if(e.keyCode === 13){
                        if(Produtos.length > 0 ) {
                          selProduto(Produtos[0])
                          document.getElementById("Quantidade").focus();
                        };
                      };
                    }}
                  />
              </MDBCol>
            ) : null }
            <>
              { Load ? (
                <LinearProgress />
              ) : null }
            </>

            { Aviso !== "" ? (
              <MDBCol md="12" style={{ padding: "0rem 0.8rem", marginBottom: 5 }}>
                <Alert severity={Sev}>{Aviso}</Alert>
              </MDBCol>
            ) : null}

            { Produtos.length > 0 ? (
              <MDBCol md="12" sm="12" style={{ padding: "0.8rem"}}>
                <ProdutosContext.Provider value={{ selProduto }}>
                  <TableProduto Produtos={Produtos} />
                </ProdutosContext.Provider>
              </MDBCol>
            ) : null }

            <MDBCol md="12" style={{ padding: "0rem 0.8rem"}}><Divider /></MDBCol>

            { ProdutoSel.id ? (
                <>
                <MDBCol md="12" sm="12" style={{ padding: "0.8rem", paddingBottom: 0 }}>
                    <b>Produto Selecionado:</b>
                </MDBCol>
                <MDBCol md="12" sm="12" style={{ padding: "0.8rem", paddingTop: 0 }}>
                    <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <>{ ProdutoSel.descricao + " | " } <>{ ProdutoSel.unidade ? (ProdutoSel.unidade) : (ProdutoSel.unidade_medida) } </> {" | R$ " + fDecimal(ProdutoSel.unitario) } </>
                    <IconButton aria-label="delete" onClick={deleteItem}>
                      <DeleteIcon />
                    </IconButton>
                    </Box>
                </MDBCol>

                <MDBCol md="12" style={{ padding: "0rem 0.8rem"}}><Divider /></MDBCol>
                </>
            ) : null }

            <MDBRow style={{ padding: "0.8rem"}}>
              <MDBCol md="6" className="fMin">
                <InputLabel >Quantidade*</InputLabel >
                <ReactNumeric
                  label="Quantidade"
                  id="Quantidade"
                  value={Quantidade}
                  minimumValue="0"
                  decimalCharacter=","
                  digitGroupSeparator="." 
                  decimalPlaces={6}
                  autoComplete="off"
                  onChange={(e, value)=> {setaQtd(value);}}
                  maximumValue={"9999999999999999999999999999999999999999999.9999"}
                  style={{ width: "100%", border: "1px solid #bdbdbd", borderRadius: 5, marginTop: 2, padding: "0.8rem", height: 40 }}
                  onKeyDown={(e) => {
                    if(e.keyCode === 13){
                        document.getElementById("Desconto").focus();
                    };
                  }}
                />
              </MDBCol>

              <MDBCol md="6" className="fMin">
                <InputLabel >Desconto (%)</InputLabel >
                <ReactNumeric
                  label="Desconto (%)"
                  value={Desconto}
                  id="Desconto"
                  minimumValue="0"
                  maximumValue="100"
                  decimalCharacter=","
                  digitGroupSeparator="." 
                  decimalPlaces={2}
                  autoComplete="off"
                  onChange={(e, value)=> {setaDesc(value);}}
                  style={{ width: "100%", border: "1px solid #bdbdbd", borderRadius: 5, marginTop: 2, padding: "0.8rem", height: 40 }}
                  onKeyDown={(e) => {
                    if(e.keyCode === 13){
                        document.getElementById("btnGravar").focus();
                    };
                  }}
                />
              </MDBCol>
            </MDBRow>

            <MDBRow style={{ padding: "0.8rem"}}>
              <MDBCol md="12" className="fMin">
              <InputLabel >Valor Previsto*</InputLabel >
              <ReactNumeric
                label="Valor Total"
                value={VlrTotal}
                minimumValue="0"
                decimalCharacter=","
                digitGroupSeparator="." 
                decimalPlaces={6}
                autoComplete="off"
                maximumValue={"9999999999999999999999999999999999999999999.9999"}
                onChange={(e, value)=> {setVlrTotal(value);}}
                disabled
                style={{ width: "100%", border: "1px solid #bdbdbd", borderRadius: 5, marginTop: 2, padding: "0.8rem", height: 40 }}
              />
              </MDBCol>
            </MDBRow>
            </MDBCol>
          </MDBRow>
        </Container>
      </ModalBody>
      <ModalFooter style={{ paddingTop: 5, paddingBottom: 5, display: "flex", justifyContent: "flex-end"}}>
        { Retorno ? <Alert severity="warning"> { Retorno }</Alert> : null }
        <MDBBtn style={{ borderRadius: 0 }} className='me-1 btnGravar' id="btnGravar" color='success' onClick={handleNavigaConf}> CONFIRMAR</MDBBtn>
        <MDBBtn style={{ borderRadius: 0 }} className='me-1' color='danger' onClick={limpaForm}> CANCELAR</MDBBtn>
      </ModalFooter>
    </Modal>
  </>
);


  function Closer(prod) {
    setOpen(false);
    aProduto(prod);
  }

  async function selProduto(produto){
    setProdutos([]);
    setProduto([]);
    setProdutoSel(produto);

    setVlrTotal(produto.unitario);
  }

  function setaQtd(qtd) {
    setQuantidade(qtd);
    calcVlr(qtd, Desconto);
  }

  function setaDesc(desc) {
    setDesconto(desc);
    calcVlr(Quantidade, desc);
  }

  function calcVlr(qtd, desc) {
    let total = 0; 
    if ((qtd !== "") && (ProdutoSel.unitario !== "")) {
      let vlrDesc = 0;
      total = ProdutoSel.unitario * qtd; 

      if((desc !== null) && (desc !== NaN)) {
        vlrDesc = ((total * desc)/100);
        setVlrDesconto(vlrDesc);
      }

      setVlrTotal(fDecimal( total - vlrDesc ));
    }
  }

  function calcValor(prod) {
    let total = 0; 
    if ((prod.quantidade !== "") && (prod.unitario !== "")) {
      let vlrDesc = 0;
      total = prod.unitario * prod.quantidade; 

      if((prod.desconto !== null) && (prod.desconto !== NaN)) {
        vlrDesc = ((total * prod.desconto)/100);
      }

      setVlrTotal(total - vlrDesc)
    }
  }

  async function getProdutos() { 
    try {
      setLoad(true);
      let Filter = "/filtrar?param=";
      if(Produto.length > 0 ) {
        Filter = "/filtrar?param=" + Produto;
      }

      const { data } = await api.get(
        `produtos${Filter}&limit=5&pagination=1`, configHearders);
  
      setProdutos(data.produtos.data);
      setLoad(false);
    } catch (erro) {
      console.log(erro);
      setLoad(false);
    }
  }

  function fDecimal(Valor) {
    return new Intl.NumberFormat("pt-BR", { currency: "BRL", minimumFractionDigits: 2 }).format(Valor);
  }
}
