import TelaConfirmar from "../../components/TelaConfirmar/TelaConfirmar";

const ConfPedido = ({ socket }) => {
  return (
    <>
      <TelaConfirmar socket={socket} />
    </>
  );
};

export default ConfPedido;
