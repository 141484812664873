import React from "react";

import "./Footer.css";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="containerFooter">
          <p>RuralHub&copy;2023</p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
