import Login from "../../components/Login/Login";

const TelaLogin = () => {
  return (
    <>
      <Login />
    </>
  );
};

export default TelaLogin;
