import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  MDBRow, MDBCol, MDBBtn
} from "mdb-react-ui-kit";
import Box from "@mui/material/Box";

import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { TextField, Divider, InputLabel, LinearProgress, Alert  } from "@mui/material";
import ReactNumeric from 'react-numeric';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import api from "../../services/api";
import PreviewProdutos from "../previewProdutos";
import ResumoPedido from "../ResumoPedido/ResumoPedido";
import "./DadosVenda.css";
import TableProduto from "../Tables/TableProduto";
import ProdutosContext from "../../context/ProdutosContext";

export default function DadosVenda() {
  const [Produto, setProduto] = useState([]);
  const [Produtos, setProdutos] = useState([]);
  const [Quantidade, setQuantidade] = useState(0);
  const [Desconto, setDesconto] = useState(0);
  const [VlrTotal, setVlrTotal] = useState(0);
  const [VlrDesconto, setVlrDesconto] = useState(null);
  
  const [timerId, settimerId] = useState(null);
  const [Load, setLoad] = useState(false);
  const [Aviso, setAviso] = useState("");
  const [Sev, setSev] = useState("");

  const [ProdutoSel, setProdutoSel] = useState([]);
  const [ListProdutos, setListProdutos] = useState([]);

  const [Retorno, setRetorno] = useState("");

  const navigate = useNavigate();

  const handleNavigaConf = () => {
    let list = [];
    setAviso("");
    
    if(ProdutoSel.id){
      let c = 0;
      if(Quantidade <= 0){
        c = c + 1;
      }

      if(VlrTotal <= 0){
        c = c + 1;
      }

      if(c === 0){
        setAviso("");
        if(ListProdutos.length > 0 ){
          list = JSON.parse(ListProdutos); 
        }

        let adc = {
          id: ProdutoSel.id,
          descricao: ProdutoSel.descricao,
          unidade: ProdutoSel.unidade,
          quantidade: Quantidade,
          vlr_total: VlrTotal,
          desconto: Desconto,
          unitario: ProdutoSel.unitario,
          vlrDesc: VlrDesconto
        };

        list[list.length] = adc;
        
        setProdutoSel([]);
        setaQtd(0);
        setaDesc(0);
        setVlrTotal(0);

        setListProdutos(JSON.stringify(list));
      }else{
        setAviso("Preencha os campos.")
        setSev("warning")
      }
    }else{
      setAviso("Selecione o produto.")
      setSev("warning")
    }
  };

  const handleNavigateAtendimentos = () => {
    localStorage.removeItem("@listProdutos")
    navigate("/Home");
  };

  const limpaForm = () => {
    deleteItem();
  };

  const deleteItem = () => {
    setProdutoSel([]);
    setQuantidade(0);
    setVlrTotal(0);
    setDesconto(0);
  }

  const configHearders = {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("@Auth:token")}`,
      cod_vendedor: parseInt(`${sessionStorage.getItem("@cod_vendedor")}`),
      cod_empresa: parseInt(`${sessionStorage.getItem("@IdEmpresa")}`),
      "Content-Type": "application/json"
    },
  };

  useEffect(() => {
    Historico();
  }, []);

  function Historico() {
    if(localStorage.getItem("@listProdutos")){
      setListProdutos(localStorage.getItem("@listProdutos"));
    }
  }

  return (
    <MDBRow>
        <MDBCol md="4" sm="12" className="boxCard"> 
          <MDBCol md={12} style={{ padding: 0, margin: 0 }}>
            <Box className="divTitle">
              Adicionar produto
            </Box>
          </MDBCol>

          <MDBCol md={12} sm="12" style={{ padding: "0.8rem"}}>
            <TextField
              value={Produto}
              placeholder="Produto"
              variant="outlined"
              fullWidth
              style={{
              marginTop: -2,
              background: "white",
              border: 0,
              boxShadow: "0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02)",
              transition: "box-shadow .15s ease",
              paddingRight: 0,
              }}
              size="small"
              id="Produto"
              autoComplete="off"
              onFocus={(e) => {
                e.preventDefault()
              }}
              onChange={(event) =>
                setProduto(event.target.value)
              }

              onKeyUp={(e) => {
                clearTimeout(timerId);

                if(Produto.length > 0) {
                  settimerId(
                    setTimeout(function () { getProdutos(); }, 300)
                  );
                }
              }}

              onKeyDownCapture={(e) => {
                if(e.keyCode === 13){
                  if(Produtos.length > 0 ) {
                    selProduto(Produtos[0])
                    document.getElementById("Quantidade").focus();
                  };
                };
              }}
            />
          </MDBCol>
          
          { Load ? (
            <LinearProgress />
          ) : null }

          { Aviso !== "" ? (
            <MDBCol md="12" style={{ padding: "0rem 0.8rem", marginBottom: 5 }}>
              <Alert severity={Sev}>{Aviso}</Alert>
            </MDBCol>
          ) : null}

          { Produtos.length > 0 ? (
            <MDBCol md="12" sm="12" style={{ padding: "0.8rem"}}>
              <ProdutosContext.Provider value={{ selProduto }}>
                <TableProduto Produtos={Produtos} />
              </ProdutosContext.Provider>
            </MDBCol>
          ) : null }

          <MDBCol md="12" style={{ padding: "0rem 0.8rem"}}><Divider /></MDBCol>

          { ProdutoSel.id ? (
            <>
              <MDBCol md="12" sm="12" style={{ padding: "0.8rem", paddingBottom: 0 }}>
                <b>Produto Selecionado:</b>
              </MDBCol>
              <MDBCol md="12" sm="12" style={{ padding: "0.8rem", paddingTop: 0 }}>
                <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <>{ ProdutoSel.descricao + " | " +  ProdutoSel.unidade +  " | R$ " + fDecimal(ProdutoSel.unitario) } </>
                  <IconButton aria-label="delete" onClick={deleteItem}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </MDBCol>

              <MDBCol md="12" style={{ padding: "0rem 0.8rem"}}><Divider /></MDBCol>
            </>
          ) : null }

          <MDBRow style={{ padding: "0.8rem"}}>
            <MDBCol md="6" className="fMin">
              <InputLabel >Quantidade*</InputLabel >
              <ReactNumeric
                label="Quantidade"
                id="Quantidade"
                value={Quantidade}
                minimumValue="0"
                decimalCharacter=","
                digitGroupSeparator="." 
                decimalPlaces={6}
                autoComplete="off"
                onChange={(e, value)=> {setaQtd(value);}}
                maximumValue={"9999999999999999999999999999999999999999999.9999"}
                style={{ width: "100%", border: "1px solid #bdbdbd", borderRadius: 5, marginTop: 2, padding: "0.8rem", height: 40 }}
                onKeyDown={(e) => {
                  if(e.keyCode === 13){
                    document.getElementById("Desconto").focus();
                  };
                }}
              />
            </MDBCol>

            <MDBCol md="6" className="fMin">
              <InputLabel >Desconto (%)</InputLabel >
              <ReactNumeric
                label="Desconto (%)"
                value={Desconto}
                id="Desconto"
                minimumValue="0"
                maximumValue="100"
                decimalCharacter=","
                digitGroupSeparator="." 
                decimalPlaces={2}
                autoComplete="off"
                onChange={(e, value)=> {setaDesc(value);}}
                style={{ width: "100%", border: "1px solid #bdbdbd", borderRadius: 5, marginTop: 2, padding: "0.8rem", height: 40 }}
                onKeyDown={(e) => {
                  if(e.keyCode === 13){
                    document.getElementById("btnGravar").focus();
                  };
                }}
              />
            </MDBCol>
          </MDBRow>

          <MDBRow style={{ padding: "0.8rem"}}>
            <MDBCol md="12" className="fMin">
              <InputLabel >Valor Previsto*</InputLabel >
              <ReactNumeric
                label="Valor Total"
                value={VlrTotal}
                minimumValue="0"
                decimalCharacter=","
                digitGroupSeparator="." 
                decimalPlaces={6}
                autoComplete="off"
                maximumValue={"9999999999999999999999999999999999999999999.9999"}
                onChange={(e, value)=> {setVlrTotal(value);}}
                disabled
                style={{ width: "100%", border: "1px solid #bdbdbd", borderRadius: 5, marginTop: 2, padding: "0.8rem", height: 40 }}
              />
            </MDBCol>
          </MDBRow>

          <MDBRow style={{ padding: "0.8rem"}}>
            <MDBCol md="6" size="sm" className="fBtn"> 
              <div className="d-grid gap-2"><MDBBtn style={{ borderRadius: 0 }} className='me-1 btnGravar' id="btnGravar" color='success' onClick={handleNavigaConf}> CONFIRMAR</MDBBtn></div>
            </MDBCol>

            <MDBCol md="6" size="sm" className="fBtn">
              <div className="d-grid gap-2"><MDBBtn style={{ borderRadius: 0 }} className='me-1' color='danger' onClick={limpaForm}> CANCELAR</MDBBtn></div> 
            </MDBCol>
          </MDBRow>
        </MDBCol>

        <MDBCol md="8" sm="12" >
          <MDBRow style={{ paddingLeft: "0.5rem"}} className="RPedido">
            <MDBCol md="12" sm={"12"} className="me-2 boxTable boxTableSize">
              <ProdutosContext.Provider value={{ aProduto }}>
                <PreviewProdutos Produtos={ListProdutos} MDel={1}/>
              </ProdutosContext.Provider>
            </MDBCol>
          </MDBRow>

          <MDBRow style={{ paddingLeft: "0.5rem", marginTop: 10 }} className="RPedido">
            <MDBCol md="12" className="me-3 boxResumo">
              <Box className="divTitle">
                 RESUMO DO PEDIDO
              </Box>
              <ProdutosContext.Provider value={{ aProduto }}>
                <ResumoPedido Produtos={ListProdutos}/>
              </ProdutosContext.Provider>
            </MDBCol>
          </MDBRow>

          <MDBCol md="12" sm="12" className="btnProd" style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", marginTop: 5 }}>
            { Retorno !== "" ? (
              <MDBCol md="6" className="me-1"><Alert severity="success" style={{ fontSize: 14 }}>{ Retorno }</Alert></MDBCol>
            ): null }
            
            <MDBCol md="3">
              <div className="d-grid gap-2"><MDBBtn style={{ borderRadius: 0 }} className='me-1' color='success' onClick={Confirmar}> CONFIRMAR</MDBBtn></div>
            </MDBCol>

            <MDBCol md="3">
              <div className="d-grid gap-2"><MDBBtn style={{ borderRadius: 0 }} className='me-1' color='danger' onClick={handleNavigateAtendimentos}> CANCELAR</MDBBtn></div>  
            </MDBCol>
          </MDBCol>
        </MDBCol>
      </MDBRow>
  );

  async function Confirmar(){
    if(ListProdutos.length > 0 ){
      localStorage.setItem("@listProdutos", ListProdutos);
      setTimeout(function () { navigate("/ConfPedido"); }, 500)
    }else{
      setRetorno("Nenhum produto selecionado")
    }
  }

  async function selProduto(produto){
    setProdutos([]);
    setProduto([]);
    setProdutoSel(produto);

    setVlrTotal(produto.unitario);
  }

  function setaQtd(qtd) {
    setQuantidade(qtd);
    calcVlr(qtd, Desconto);
  }

  function setaDesc(desc) {
    setDesconto(desc);
    calcVlr(Quantidade, desc);
  }

  function calcVlr(qtd, desc) {
    let total = 0; 
    if ((qtd !== "") && (ProdutoSel.unitario !== "")) {
      let vlrDesc = 0;
      total = ProdutoSel.unitario * qtd; 

      if((desc !== null) && (desc !== NaN)) {
        vlrDesc = ((total * desc)/100);
        setVlrDesconto(vlrDesc);
      }

      setVlrTotal(fDecimal( total - vlrDesc ));
    }
  }

  async function getProdutos() { 
    try {
      setLoad(true);
      let Filter = "";
      if(Produto.length > 0 ) {
        Filter = "/filtrar?param=" + Produto;
      }
      
      const { data } = await api.get(
        `produtos${Filter}&limit=5&pagination=1`, configHearders);
  
      setProdutos(data.produtos.data);
      setLoad(false);
    } catch (erro) {
      console.log(erro);
      setLoad(false);
    }
  }

  function fDecimal(Valor) {
    return new Intl.NumberFormat("pt-BR", { currency: "BRL", minimumFractionDigits: 2 }).format(Valor);
  }

  function aProduto(a){
    setListProdutos(a)
  }
}
