import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Link, Navigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import MuiAlert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { brown } from "@mui/material/colors";
import Logo from "../../assets/img/argon-react.png"

import "./Login.css";

const TelaLogin = () => {
  const theme = createTheme({
    palette: {
      primary: {
        main: brown[500],
      },
      secondary: {
        main: "#525252",
      },
    },
  });

  const [email, setEmail] = useState("");//willian.hubner@hotmail.co.uk
  const [senha, setSenha] = useState(""); //456789
  const { signIn, signed, erro, limpaErro, loanding, errorServer } = useContext(AuthContext);
  const navigate = useNavigate();

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (!erro) {
      setOpen(false);
      return;
    }

    setOpen(true);

    const timer = setTimeout(() => {
      setOpen(false);
      limpaErro();
    }, 4000);
    return () => clearTimeout(timer);
  }, [erro]);

  useEffect(() => {
    if (sessionStorage.getItem("@Auth:token") !== null) {
      navigate("/home");
    }
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      email,
      senha,
    };

    //console.log(signIn(data))
    await signIn(data);
  };

  if (!signed) {
    return (
      <div className="containerLogin">
        <div className="containerImgLogo">
          <img
            src={Logo}
            alt="imagem da logo da empresa"
          />
        </div>
        <form onSubmit={handleSubmit} className="containerFormulario">
          <div className="containerInputs">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <label>Email</label>
          </div>
          <div className="containerInputs">
            <input
              type="password"
              value={senha}
              onChange={(e) => setSenha(e.target.value)}
              required
            />
            <label>Senha</label>
            {open && (
              <div className="containerErro">
                <Alert severity="error" sx={{ width: "100%" }}>
                  {erro}
                </Alert>
              </div>
            )}
          </div>
          { /*
           {errorServer !== "" ? (
              <div className="containerErro">
                <Alert severity="error" sx={{ width: "100%" }}>
                  {errorServer}
                </Alert>
              </div>
            ) : null }
          */}

          <div className="containerButton">
            {loanding ? (
              <ThemeProvider theme={theme}>
                <Box sx={{ display: "flex" }}>
                  <CircularProgress color="secondary" />
                </Box>
              </ThemeProvider>
            ) : (
              <>
                <button type="submit" style={{textAlign: "center"}}>ENTRAR</button>
                {/*<div className="containerFinal">
                  <p className="textoFinal">
                    Não tem conta? <Link to="/cadastro">Cadastre-se</Link>
                  </p>
                </div>*/}
              </>
            )}
          </div>
        </form>
      </div>
    );
  } else {
    return <Navigate to="/home" />;
  }
};

export default TelaLogin;
