import React, { useEffect, useState, useContext  } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, tableCellClasses, styled } from '@mui/material';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import ProdutosContext from "../../context/ProdutosContext";

import "./index.css";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#D9D9D9",
    color: "#424242",
    fontWeight: "bold",
    borderRadius: 0
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    borderRadius: 0
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
    borderRadius: "0px"
  },
}));

export default function PreviewProdutos({ Produtos, MDel }) {
  const [LProduto, setLProduto] = useState([]);
  
  const { aProduto } = useContext(ProdutosContext);

  useEffect(() => {
    if(typeof Produtos === "object"){
      setLProduto(Produtos)
    }else{
      setLProduto(JSON.parse(Produtos))
    }

  }, [Produtos, MDel]);

  function deleteItem(index) {
    setLProduto(LProduto.splice(index, 1));
    aProduto(JSON.stringify(LProduto.splice(index, 1)));
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="a dense table" responsive style={{ borderRadius: 0 }}> 
          <TableHead style={{ borderRadius: 0 }}>
            <TableRow>
              <StyledTableCell align="left">Descrição</StyledTableCell>
              <StyledTableCell align="right">Unitário</StyledTableCell>
              <StyledTableCell align="right">Quantidade</StyledTableCell>
              <StyledTableCell align="right">Desconto</StyledTableCell>
              <StyledTableCell align="right">Total</StyledTableCell>
              { MDel == 1 ? <StyledTableCell></StyledTableCell> : null }
            </TableRow>
          </TableHead>
          <TableBody style={{ borderRadius: 0 }}>
            {LProduto.map((row, i) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell align="left">{row.descricao}</StyledTableCell>
                <StyledTableCell align="right">R$ {fDecimal(row.unitario)}</StyledTableCell>
                <StyledTableCell align="right">{fQtd(row.quantidade)} { row.unidade ? row.unidade : row.unidade_medida } </StyledTableCell>
                <StyledTableCell align="right">{ row.desconto }%</StyledTableCell>
                <StyledTableCell align="right">R$ { row.vlr_total ? row.vlr_total : fDecimal(calcVlr(row.quantidade, row.desconto, row.unitario))}</StyledTableCell>
                { MDel == 1 ? 
                  <StyledTableCell>
                    <IconButton aria-label="delete" onClick={(e) => deleteItem(i)}>
                      <DeleteIcon />
                    </IconButton>
                  </StyledTableCell>
                 : null }
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );

  function fDecimal(Valor) {
    return new Intl.NumberFormat("pt-BR", { currency: "BRL", minimumFractionDigits: 2 }).format(Valor);
  }

  function fQtd(Valor) {
    return new Intl.NumberFormat("pt-BR").format(Valor);
  }

  function calcVlr(qtd, desc, vlrun) {
    let total = 0; 
    if ((qtd !== "") && (vlrun !== "")) {
      let vlrDesc = 0;
      total = vlrun * qtd; 

      if((desc !== null) && (desc !== NaN)) {
        vlrDesc = ((total * desc)/100);
      }

      return total - vlrDesc;
    }
  }
}
