import { VscSearchStop } from "react-icons/vsc";

import "./SemResultados.css";

const SemResultados = (props) => {
  return (
    <div className="containerSemResultados">
      <VscSearchStop />
      <h1>{props.textoSemResultados}</h1>
    </div>
  );
};

export default SemResultados;
