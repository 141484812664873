import React, { useEffect, useState } from "react";
import {
  MDBRow, MDBCol
} from "mdb-react-ui-kit";
import Box from "@mui/material/Box";

import "./ResumoPedido.css";
import { Container } from "@mui/material";

export default function ResumoPedido(props) {
  const [TotalItens, setTotalItens] = useState([]);
  const [Quantidade, setQuantidade] = useState([]);
  const [DescTotal, setDescTotal] = useState([]);
  const [VlrTotal, setVlrTotal] = useState([]);
  const [Mudo, setMudo] = useState(false);

  useEffect(() => {
    if(props.Produtos.length > 0 ){
      let prod = JSON.parse(props.Produtos);
      calcTotal(prod);
    }

  }, [props.Produtos]);

  return (
    <Container key={Mudo}>
      <MDBRow>
        <MDBCol md="12" className="alinha">
          <Box className="DescCampo">Total de itens: <b>{TotalItens}</b></Box>
        </MDBCol>
        <MDBCol md="12" className="alinha">
          <Box className="DescCampo">Quantidade total: <b>{fDecimal(Quantidade)}</b></Box>
        </MDBCol>
      
        <MDBCol md="6" className="alinha">
          <Box className="DescCampo">Desconto total: <b>R$ {fDecimal(DescTotal)}</b></Box>
        </MDBCol>
    
        <MDBCol md="6" className="alinha2">
          <Box className="Value"><b>R$ { fDecimal(VlrTotal)}</b></Box>
        </MDBCol>
      </MDBRow>
    </Container>
  );

  function calcTotal(prod){
    let desc = 0;
    let total = 0;
    let qtd = 0;
    let descVlr = 0;

    for (let i = 0; i < prod.length; i++) {
      desc = desc + prod[i].desconto

      let tt = parseFloat(((prod[i].vlr_total).replace(/[.]+/g,'')).replace(/[,]+/g,'.'))
      total = total + tt
      qtd = qtd + prod[i].quantidade
      descVlr = descVlr + calcVlr(prod[i].quantidade, prod[i].desconto, prod[i].unitario)
    }

    setQuantidade(qtd);
    setDescTotal(descVlr);
    setVlrTotal(total);
    setTotalItens(prod.length)
  }

  function calcVlr(qtd, desc, unitario) {
    let total = 0; 
    if ((qtd !== "") && (unitario !== "")) {
      let vlrDesc = 0;
      total = unitario * qtd; 

      if((desc !== null) && (desc !== NaN)) {
        vlrDesc = ((total * desc)/100);
      }

      return vlrDesc;
    }
  }

  function fDecimal(Valor) {
    return new Intl.NumberFormat("pt-BR", { currency: "BRL", minimumFractionDigits: 2 }).format(Valor);
  }
};
