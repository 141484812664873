import React, { useState, useEffect, useContext } from "react";
import { Button, Modal, ModalBody, ModalFooter, Row, Col, Container } from "reactstrap";
import {
    LinearProgress
} from "@material-ui/core";
import { Divider } from "@mui/material";
import {
  MDBIcon
} from "mdb-react-ui-kit";
import PedidosContext from "../../context/PedidosContext";
import { makeStyles } from "@material-ui/core/styles";
import SemResultados from "../SemResultados/SemResultados";
import PDFDocumentsGenerator from "../../reports/ImpPedido";

import {
  Table, TableBody, TableCell, Alert, TableHead, TableRow, Paper, tableCellClasses, styled, TableContainer
} from '@mui/material';

import "./Modais.css";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#D9D9D9",
    color: "#424242",
    fontWeight: "bold",
    borderRadius: 0,
    margin: 2
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    borderRadius: 0
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
    borderRadius: "0px"
  },
}));

const useStyles = makeStyles((theme) => ({
  thead: { textAlign: "left" },
  ThChave: { textAlign: "left" },
  tbodt: { textAlign: "left", fontSize: 16, fontFamily: "Roboto, Helvetica, Arial, sans-serif" },
  tbChave: { textAlign: "left", fontSize: 16, fontFamily: "Roboto, Helvetica, Arial, sans-serif" },
}));

export default function DetPedido(props) {
  const classes = useStyles();
  const [Open, setOpen] = useState(false);
  const [Pedido, setPedido] = useState([]);
  const [PedImp, setPedImp] = useState([]);
  const [ItensPedido, setItensPedido] = useState([]);
  const [Load, setLoad] = useState(false);

  const { FechaModal } = useContext(PedidosContext);

  useEffect(() => {
    if (props.abre) {
      if (props.Pedido !== "") {
        let ped = props.Pedido;
        ped.usuario = JSON.parse(sessionStorage.getItem("@user"));
        ped.cnpj = cpf_Cnpj(sessionStorage.getItem("@CnpjEmpresa"));
        ped.nome = sessionStorage.getItem("@razao_social");

        setPedImp(JSON.stringify(ped));
        setPedido(props.Pedido);
        setItensPedido(props.Pedido.item)
      }

      setOpen(true);
    }
  }, [props.abre]);

  return (
    <>
      <Modal centered size="lg" isOpen={Open}>
        <ModalBody>
          <Container className="py-lg">
            <Row>
              <Col lg="12" style={{ marginBottom: 20 }} >
                <b>Pedido: {lpad(Pedido.numero)}</b>
                <Divider style={{ background: "#4f4f4f" }}/>
              </Col>
            </Row>
          
          <div className="mt-1"></div>
          {Load ? <LinearProgress color="secondary" /> : null}

          <>
            { Pedido.id ? (
              <>
                <Row>
                  <Col lg="4">
                    <Col lg="12">
                        <b>Cliente</b>
                    </Col>
                    <Col lg="12">
                      { Pedido.cliente.razao_social }
                    </Col>
                  </Col>
                  <Col lg="4">
                    <Col lg="12">
                        <b>CPF/CNPJ</b>
                    </Col>
                    <Col lg="12">
                      { cpf_Cnpj(Pedido.cliente.cnpj_cpf) }
                    </Col>
                  </Col>
                  <Col lg="4">
                    <Col lg="12">
                        <b>Inscrição estadual</b>
                    </Col>
                    <Col lg="12">
                      { Pedido.cliente.ie }
                    </Col>
                  </Col>
                  <Col lg="12">
                    <Col lg="12">
                        <b>Endereço</b>
                    </Col>
                    <Col lg="12">
                      { Pedido.cliente.endereco + ", Nº "+ Pedido.cliente.numero + ". Bairro: " + Pedido.cliente.bairro + ". " + Pedido.cliente.cidade + "/" + Pedido.cliente.uf}
                    </Col>
                  </Col>
                </Row>
                <Divider style={{ background: "#4f4f4f", marginTop: 5, marginBottom: 5 }} />
                <Row>
                  <Col lg="12">
                    <b>Dados do Pedido</b>
                  </Col>
                  <Col lg="6">
                    <Col lg="12">
                      <b>CFOP</b>
                    </Col>
                    <Col lg="12">{ Pedido.cfop.cfop + " - " + Pedido.cfop.descricao }</Col>
                  </Col>
                  <Col lg="4">
                    <Col lg="12">
                      <b>Forma de Pagamento</b>
                    </Col>
                    <Col lg="12">{ Pedido.forma_pgto.nome }</Col>
                  </Col>
                </Row>
                <Divider style={{ background: "#4f4f4f", marginTop: 5, marginBottom: 5 }} />
                <Row>
                  <Col lg="12"><b>Produtos</b></Col>
                  <Col lg="12" style={{ marginBottom: 5 }}> 
                    {ItensPedido.length > 0 ? (
                      <TableContainer component={Paper} style={{ padding: 5 }} >
                        <Table aria-label="a dense table" responsive style={{ borderRadius: 0 }}>  
                          <TableHead style={{ borderRadius: 0 }}>
                              <TableRow>
                                <StyledTableCell align="left">Descrição</StyledTableCell>
                                <StyledTableCell align="left">UM</StyledTableCell>
                                <StyledTableCell align="right">Valor Unitário</StyledTableCell>
                                <StyledTableCell align="right">Total de itens</StyledTableCell>
                                <StyledTableCell align="right">Desconto</StyledTableCell>
                                <StyledTableCell align="right">Vlr. total (estimado)</StyledTableCell>
                              </TableRow>
                          </TableHead>
                          <TableBody style={{ borderRadius: 0 }}>
                              {ItensPedido.map((row) => (
                              <StyledTableRow key={row.id}>
                                  <StyledTableCell align="left"> {row.descricao}</StyledTableCell>
                                  <StyledTableCell align="left">{row.unidade}</StyledTableCell>
                                  <StyledTableCell align="right">R$ {fDecimal(row.unitario)}</StyledTableCell>
                                  <StyledTableCell align="right">{row.quantidade}</StyledTableCell>
                                  <StyledTableCell align="right">{row.desconto} %</StyledTableCell>
                                  <StyledTableCell align="right">{"R$ " + fDecimal(calcVlr(row.quantidade, row.desconto, row.unitario))}</StyledTableCell>
                              </StyledTableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (
                      <SemResultados textoSemResultados="Não encontramos nenhum registro." />
                    )}
                    <Divider style={{ background: "#4f4f4f", marginTop: 5, marginBottom: 5 }} />
                  </Col>
                  <Col lg="4">
                    <Col lg="12">
                      <b>Total de itens</b>
                    </Col>
                    <Col lg="12">{ fQtd(Pedido.total_itens) }</Col>
                    <Divider className="dTotalizador" />
                  </Col>
                  <Col lg="4">
                    <Col lg="12">
                      <b>Valor total</b>
                    </Col>
                    <Col lg="12">R${ " " + fDecimal(Pedido.valor_total) }</Col>
                    <Divider className="dTotalizador" />
                  </Col>
                  <Col lg="4">
                    <Col lg="12">
                      <b>Desconto total</b>
                    </Col>
                    <Col lg="12">{ fQtd(Pedido.desconto) + " "}%</Col>
                    <Divider className="dTotalizador" />
                  </Col>
                </Row>               
              </>
            ) : null }
          </>
          </Container>
        </ModalBody>
        <ModalFooter style={{ paddingTop: 5, paddingBottom: 5 }}>
          <>
            { Pedido.sincronizado === 1 ? (//Pedido.log_sincro
              <Button style={{ borderRadius: 0}} color='info' onClick={() => PDFDocumentsGenerator(PedImp)}> <MDBIcon fas icon="print" /> Imprimir</Button>
            ) : (
              <Alert severity="warning"> Pedido nao sinconizado.</Alert>
            )}
            <Button style={{ borderRadius: 0}} onClick={() => Closer()} color="danger">
              Fechar
            </Button>
          </>
        </ModalFooter>
      </Modal>
    </>
  );

  function Closer() {
    setOpen(false);
    FechaModal();
  }

  function cpf_Cnpj(value) {
    if (value !== "" && value !== undefined) {
      const cnpjCpf = value.replace(/\D/g, "");

      if (cnpjCpf.length === 11) {
        return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
      }

      return cnpjCpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "$1.$2.$3/$4-$5");
    }
  }

  function lpad(num) {
    let size = 6;
    let s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }

  function fDecimal(Valor) {
    return new Intl.NumberFormat("pt-BR", { currency: "BRL", minimumFractionDigits: 2 }).format(Valor);
  }

  function fQtd(Valor) {
    return new Intl.NumberFormat("pt-BR").format(Valor);
  }

  function calcVlr(qtd, desc, vlrun) {
    let total = 0; 
    if ((qtd !== "") && (vlrun !== "")) {
      let vlrDesc = 0;
      total = vlrun * qtd; 

      if((desc !== null) && (desc !== NaN)) {
        vlrDesc = ((total * desc)/100);
      }

      return total - vlrDesc;
    }
  }
}
